// Steps to produce this file:
// 1 - Optimize svg with svgo (install as npm -i svgo): find . -exec svgo -i {} \;
// 2 - Generate it with : find . -exec echo ".{} {background: url('../assets/images/hazmats/{}');}" \; | sort -n
// 3 - Then find replace regexp "\.\./ADR_(\d)\.?(\d|\w)?.svg" by ".DL-$1-$2"
// 4 - Finally clean the css classes and the "./" in svg path
.danger-label {
  display: inline-block;
  margin-right: 10px;
  width: 80pt;
  height: 80pt;
}
.DL_1_4 {background: url('../assets/images/hazmats/ADR_1.4.svg');}
.DL_1_5 {background: url('../assets/images/hazmats/ADR_1.5.svg');}
.DL_1_6 {background: url('../assets/images/hazmats/ADR_1.6.svg');}
.DL_1 {background: url('../assets/images/hazmats/ADR_1.svg');}
.DL_2_1 {background: url('../assets/images/hazmats/ADR_2.1.svg');}
.DL_2_2 {background: url('../assets/images/hazmats/ADR_2.2.svg');}
.DL_2_3 {background: url('../assets/images/hazmats/ADR_2.3.svg');}
.DL_3 {background: url('../assets/images/hazmats/ADR_3.svg');}
.DL_4_1 {background: url('../assets/images/hazmats/ADR_4.1.svg');}
.DL_4_2 {background: url('../assets/images/hazmats/ADR_4.2.svg');}
.DL_4_3 {background: url('../assets/images/hazmats/ADR_4.3.svg');}
.DL_5_1 {background: url('../assets/images/hazmats/ADR_5.1.svg');}
.DL_5_2 {background: url('../assets/images/hazmats/ADR_5.2.svg');}
.DL_6_1 {background: url('../assets/images/hazmats/ADR_6.1.svg');}
.DL_6_2 {background: url('../assets/images/hazmats/ADR_6.2.svg');}
.DL_7_A {background: url('../assets/images/hazmats/ADR_7A.svg');}
.DL_7_B {background: url('../assets/images/hazmats/ADR_7B.svg');}
.DL_7_C {background: url('../assets/images/hazmats/ADR_7C.svg');}
.DL_7_E {background: url('../assets/images/hazmats/ADR_7E.svg');}
.DL_8 {background: url('../assets/images/hazmats/ADR_8.svg');}
.DL_9 {background: url('../assets/images/hazmats/ADR_9.svg');}
