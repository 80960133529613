@import 'bourbon', '../../../commons/styles/variables', '../../../commons/styles/icons';

h1.title {
  &.validated-pre-advice-title,
  &.received-pre-advice-title {
    margin-top: 10px;
    padding: 15px 0 15px 90px;

    &::after {
      content: '';
      position: absolute;
      left: 20px;
      top: 0;
      width: 60px;
      height: 60px;
      border-radius: 30px;
      background: icon(checkmark, rgba(255, 255, 255, 0.99)) no-repeat 50% 50%;
      background-size: 30px 30px;
    }
  }

  &.validated-pre-advice-title {
    &::after {
      background-color: $color-success;
    }
  }

  &.received-pre-advice-title::after {
    background-color: $color-received-pre-advice;
  }
}
