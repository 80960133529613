.table {
  width: 100%;
  padding-bottom: 72px;

  &-header {
    display: flex;
    flex-flow: row nowrap;
    height: 28px;
    line-height: 28px;
    padding: 0 20px;
    cursor: default;
    border-bottom: 1px solid $base-border-color-dark;

    > div {
      font-weight: bold;
      font-size: 75%;
      text-transform: uppercase;
      letter-spacing: 1px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $color-text-gray;
      user-select: text;
    }
    a {
      color: $color-accent;
    }
  }

  &-body {
    height: 100%;
    padding-bottom: 28px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;

    &-row {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: center;
      text-align: left;
      cursor: pointer;
      padding: 20px;
      border-bottom: 1px solid $base-border-color;

      @media (max-width: $device-width-tablet - 1px) {
        padding: 10px;
      }
    }
    &-row * {
      user-select: text;
    }

    &-row.disabled {
      cursor: default;
    }

    .cell-train-no {
      font-size: 80%;
      font-weight: 900;
      text-transform: uppercase;
      letter-spacing: 2px;
      color: #fff;
      background-color: $color-gray;
      padding: 3px 5px;
      border-radius: calc($base-border-radius / 2);
      text-align: center;
    }

    .cell-date {
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden;
    }

    .cell-contract {
      color: $color-text-gray;
    }

    .cell-route {
      width: 100%;
      margin-top: 5px;
      font-size: 90%;
    }
    .cell-route-from {
      display: inline;
    }
    .cell-route-to {
      display: inline;
    }

    .cell-route-to::before,
    .cell-receiver::before {
      content: '\002794';
      padding: 0 10px;
      font-size: 110%;
      display: inline-block;
      font-family: Meiryo, sans-serif;
    }
  }

  &-message {
    margin: 0 20px 20px;
    font-size: 90%;
    @media (max-width: $device-width-tablet - 1px) {
      margin-bottom: 0;
    }
  }

  .cell-train-no {
    width: 105px;
    margin-right: 40px;
  }

  .loader {
    position: absolute;
    top: 0;
  }
}
