@import 'bourbon', '../../../commons/styles/variables';

.table-users {
  font-size: 90%;

  .table-body-row {
    &.inactive,
    &.inactive .cell-contracts {
      color: $color-text-gray;
    }

    &.inactive .tag,
    & .tag.inactive {
      background-color: rgba($color-gray, 0.2);
    }

    .name {
      font-size: 120%;
      font-weight: bold;
      margin-bottom: 5px;
    }

    .tag {
      display: inline-block;
      color: #fff;
      background-color: $color-gray;
      font-size: 90%;
      height: 24px;
      line-height: 24px;
      margin: 0 5px 5px 0;
      padding: 0 10px;
      border-radius: 12px;
      text-align: center;
    }

    .cell-status::after {
      content: '';
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      border: 1px solid $color-gray;
    }

    .cell-status.on::after {
      border-color: $color-success;
      background-color: $color-success;
    }

    .cell-contracts {
      color: $color-text-default;
      padding-top: 5px;
      margin: -5px 0;
    }
  }

  .cell-username {
    flex: 3;
  }

  .cell-email {
    flex: 2;
  }

  .cell-contracts {
    flex: 2;
  }

  .cell-company {
    flex: 2;
  }

  .cell-status {
    width: 100px;
    text-align: center;
  }

  @media (max-width: $device-width-tablet - 1px) {
    .table-body-row {
      flex-direction: column;
      align-items: stretch;
      position: relative;

      .cell-contracts {
        margin-top: 2px;
      }
    }

    .cell-username {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .cell-status {
      position: absolute;
      bottom: 8px;
      right: 12px;
      width: unset;
    }
  }
}
