@import 'bourbon', '../../../commons/styles/variables';

ul.damages {
  padding: 0;
  width: 100%;

  button.add-damage {
    //margin-top: 15px;
    margin: 0;
  }

  li {
    width: 100%;
    list-style-type: none;
    padding: 15px;
    margin-bottom: 10px;
    box-sizing: border-box;
    border-radius: $base-border-radius;
    border: 1px solid $base-border-color;

    .subtitle {
      font-size: 120%;
      margin: 0 auto 0 0;
    }

    &.add-button {
      border: 0;
      padding: 0;
    }

    .header {
      display: flex;
      flex-flow: row nowrap;
      width: 100%;
      align-items: center;

      button {
        margin: 0;
      }
    }

    .damage-report-form-damage {
      .dropdown-body {
        -webkit-overflow-scrolling: initial;
      }

      &-code {
        flex-basis: 33%;
        flex-grow: 1;
        flex-shrink: 1;
        padding-right: 2%;

        span.autre-message {
          color: $color-text-gray;
          font-size: 0.8em;
        }
      }

      &-description {
        flex-basis: 67%;
        flex-grow: 1;
        flex-shrink: 1;
      }

      > * > div.input {
        width: 100%;
      }
    }
  }
}
