$z-index: (
  drag: 900,
  toast: 800,
  modal: 700,
  overlay: 600,
  dropdown: 500,
  loader: 400,
  sidebar-mobile: 370,
  sidebar-backdrop: 360,
  sidebar-button: 350,
  topbar: 300,
  content: 200,
  sidebar: 100,
);

@function z-index($key) {
  @return map-get($z-index, $key);
}

@mixin z-index($key) {
  z-index: z-index($key);
}
