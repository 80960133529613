@import '../../../../commons/styles/variables';

.damage-report-annexes {
  a[href] {
    color: $color-accent;
  }

  .drop-zone {
    margin: 20px;
    padding: 20px;
    min-height: 100px;
    border: 2px dashed #666;
    border-radius: 12px;
    color: #000;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    &.active,
    .success {
      border-color: $color-success;
      color: $color-success;
    }

    &.reject,
    .error {
      border-color: $color-danger;
      color: $color-danger;
    }
  }

  .damage-report-annexes-list {
    list-style: none;
    padding-left: 0;

    li {
      display: flex;

      &:not(:last-child) {
        border-bottom: 1px solid $base-border-color;
      }

      > * {
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 100%;
      }

      div {
        display: flex;
        width: 100%;

        &.pending {
          color: $color-text-gray;
          font-style: italic;
        }

        &.clickable {
          cursor: pointer;
        }
      }

      span.attachment-name {
        padding: 15px 0;
      }
    }
  }
}
