@import 'bourbon', 'src/commons/styles/variables';

.info-pin {
  display: block;
  position: absolute;
  right: 0;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  background: $color-accent;
  color: #fff;
  border-radius: 50%;
}
