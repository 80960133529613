@import 'bourbon', '../../commons/styles/variables', '../../commons/styles/icons', '../../commons/styles/z-index',
  '../trainStatusStyles';

.table-trains {
  @include z-index(content);

  border-top: 1px solid $base-border-color-dark;

  .table-body-row {
    position: relative;
    background-color: $color-state-empty;

    &.not-validated .cell-train-no {
      color: $color-state-not-validated;
    }

    &.validated .cell-train-no {
      color: $color-state-validated;
    }

    &.not-validated {
      background-color: $color-state-not-validated;
    }

    &.validated {
      background-color: $color-state-validated;

      &.received-pre-advice {
        background-color: $color-state-received-pre-advice-background;
        background-image: url('../../commons/assets/images/icon-pre-advice_received-not-used.svg');

        &.received-pre-advice-used {
          background-image: url('../../commons/assets/images/icon-pre-advice_received-used.svg');
        }
      }
    }

    .cell-train-header {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 8px;
    }

    .cell-train-step,
    .cell-train-arrival {
      width: 100%;
      display: flex;
      align-items: center;
      position: relative;
      min-height: 24px;
      margin: 3px 0 3px 60px;
      opacity: 0.5;

      &:before {
        display: block;
        content: '';
        position: absolute;
        left: -20px;
        top: 6px;
        width: 12px;
        height: 12px;
        background-image: url('../../commons/assets/images/icon_train_end.svg');
        background-size: contain;
        background-position: top center;
      }

      &.active-departure,
      &.active-arrival {
        opacity: 1;
      }
    }

    .cell-train-step {
      .cell-status {
        position: absolute;
        top: 22px;
        left: -60px;
        width: 35px;
        height: 8px;
        background: url('../../commons/assets/images/icon-comp-state-empty.svg') center center no-repeat;

        &.not-validated {
          background-image: url('../../commons/assets/images/icon-comp-state-not-validated.svg');
        }

        &.validated {
          background-image: url('../../commons/assets/images/icon-comp-state-validated.svg');
        }
      }

      &:before {
        background-image: url('../../commons/assets/images/icon_train_start.svg');
      }

      &:after {
        display: block;
        content: '';
        position: absolute;
        top: 20px;
        left: -15px;
        height: calc(100% - 10px);
        border-right: 2px dotted #293e69;
      }

      &.active-departure {
        &:after {
          border-right-style: solid;
        }
      }

      &.active-arrival {
        .cell-status,
        &:after {
          opacity: 0.5;
        }
      }
    }

    .cell-step-no {
      position: absolute;
      left: -60px;
      top: 6px;
      width: 35px;
      margin-right: 25px;
      font-size: 50%;
      font-weight: 600;
      text-transform: uppercase;
      color: #fff;
      background-color: $color-gray;
      padding: 1px 2px;
      border-radius: calc($base-border-radius / 2);
      text-align: center;
    }
  }

  .cell-date {
    width: 150px;
  }

  .cell-composition {
    text-align: right;
    margin-left: auto;
  }

  @media (max-width: $device-width-tablet - 1px) {
    .cell-train-header {
      .cell-train-no {
        margin-right: 10px;
      }

      .cell-contract {
        font-size: 80%;
      }
    }

    .cell-date {
      width: 115px;
      flex-shrink: 0;
    }
  }
}
