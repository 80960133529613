.rdt {
  position: relative;

  &::before {
    content: '';
    width: $base-button-height;
    height: $base-button-height;
    position: absolute;
    top: 2px;
    right: -2px;
    background-image: icon(arrow-down, $color-gray);
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

  input {
    position: relative;
    width: 100%;
    height: $base-button-height;
    padding: 10px 30px 10px 10px;
    text-align: left;
    color: $color-text-default;
    border-radius: $base-border-radius;
    border: 1px solid $base-border-color;
    outline: none;
    cursor: pointer;
    font-family: 'Lato', sans-serif;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: transparent;
    appearance: none;

    ::placeholder {
      font-family: 'Lato', sans-serif;
      color: $color-text-gray;
    }
    &:disabled {
      cursor: default;
    }

    &:read-only:not(:disabled) {
      background-color: #fff;
    }
  }

  &.rdtOpen input {
    border-color: $color-accent;
    box-shadow: inset 0 0 0 1px $color-accent;
  }
  &.rdtOpen .rdtPicker {
    display: block;
  }

  &.icon input {
    padding-left: 40px;
    background: icon(date, $color-gray) 10px 50% no-repeat;
    background-size: 20px 20px;
  }
  &.icon.rdtOpen input {
    background-image: icon(date, rgba($color-accent, 0.99));
  }

  &.align-right .rdtPicker {
    right: 0;
    left: auto;
  }
}

.rdtPicker {
  z-index: 500;
  position: absolute;
  width: 100%;
  left: 0;
  min-width: 240px;
  padding: 10px 15px 15px;
  margin-top: 10px;
  background-color: #fff;
  border-radius: $base-border-radius;
  box-shadow: 0 20px 80px rgba(0, 0, 0, 0.2);
  display: none;
  animation: dropdown-on calc($base-animation-time / 2);

  .rdtYears,
  .rdtMonths,
  .rdtDays,
  .rdtTime {
    animation: dropdown-on calc($base-animation-time / 2);
  }

  table {
    width: 100%;
    margin: 0;
  }

  td,
  th {
    text-align: center;
    height: 30px;
  }

  td {
    cursor: pointer;
    border-radius: calc($base-border-radius / 2);
    &.rdtToday {
      position: relative;
    }
    &:hover {
      background-color: rgba($color-accent, 0.1);
    }

    &.rdtOld,
    &.rdtNew {
      color: $color-text-gray;
    }

    &.rdtToday {
      color: #fff;
      background-color: $color-neutral;
    }

    &.rdtActive,
    &.rdtActive:hover {
      color: #fff;
      background-color: $color-accent;
    }
  }

  td span.rdtOld {
    color: $color-text-gray;
  }

  td.rdtDisabled,
  td.rdtDisabled:hover,
  td span.rdtDisabled,
  span.rdtDisabled:hover,
  th.rdtDisabled,
  th.rdtDisabled:hover {
    background: none;
    color: rgba($color-text-gray, 0.5);
  }
  .rdtTimeToggle {
    text-align: center;
  }
  .rdtTime td:hover {
    background: transparent;
  }
  .dow {
    width: 14.2857%;
  }
  th.rdtSwitch {
    width: 100px;
  }

  th.rdtNext,
  th.rdtPrev {
    min-width: 20px;

    span {
      display: block;
      height: 30px;
      cursor: pointer;
      text-indent: -10000px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      border-radius: calc($base-border-radius / 2);
    }

    span:hover {
      background-color: rgba($color-accent, 0.1);
    }
  }

  th.rdtNext span {
    background-image: icon(arrow-right, rgba($color-text-gray, 0.99));
    &:hover {
      background-image: icon(arrow-right, rgba($color-accent, 0.99));
    }
  }

  th.rdtPrev span {
    background-image: icon(arrow-left, rgba($color-text-gray, 0.99));
    &:hover {
      background-image: icon(arrow-left, rgba($color-accent, 0.99));
    }
  }

  th.rdtSwitch {
    cursor: pointer;
    border-radius: calc($base-border-radius / 2);
    &:hover {
      background-color: rgba($color-accent, 0.1);
    }
  }

  thead button {
    width: 100%;
    height: 100%;
  }

  button:hover {
    background-color: rgba($color-accent, 0.1);
  }

  thead button {
    width: 100%;
    height: 100%;
  }
}

.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
}

td.rdtMonth:hover,
td.rdtYear:hover {
  background-color: rgba($color-accent, 0.1);
}

.rdtCounters {
  display: inline-block;
}
.rdtCounters > div {
  float: left;
}
.rdtCounter {
  width: 50px;
}

.rdtCounterSeparator {
  font-size: 220%;
  font-weight: 300;
  line-height: 150px;
}

.rdtCounter .rdtBtn {
  height: 50px;
  line-height: 50px;
  cursor: pointer;
  display: block;
  border-radius: calc($base-border-radius / 2);
}
.rdtCounter .rdtBtn:hover {
  background-color: rgba($color-accent, 0.1);
}

.rdtCounter .rdtCount {
  height: 50px;
  font-size: 220%;
  font-weight: 300;
  line-height: 50px;
}

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
}

.rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px;
}

.input-error {
  .rdt {
    input {
      border-color: $color-danger;
    }
  }
}
