@import 'bourbon', '../../commons/styles/variables', '../../commons/styles/icons', '../../commons/styles/z-index',
  '../../commons/styles/variables';

.table-damage-reports {
  @include z-index(content);

  .table-body-row {
    position: relative;
    padding-right: 60px;
    background: $color-state-empty url('../../commons/assets/images/icon-damage-report-state-empty.svg') right 20px
      center no-repeat;

    &.submitted .cell-train-no {
      color: $color-state-not-validated;
    }

    &.validated .cell-train-no {
      color: $color-state-validated;
    }

    &.in-progress {
      background-image: url('../../commons/assets/images/icon-damage-report-state-in-progress.svg');
    }

    &.submitted {
      background-image: url('../../commons/assets/images/icon-damage-report-state-submitted.svg');
    }

    &.validated {
      background-color: $color-state-validated;
      background-image: url('../../commons/assets/images/icon-damage-report-state-validated.svg');
    }

    &.disabled {
      background-color: $color-state-disabled;
      background-image: url('../../commons/assets/images/deactivate.svg');
    }

    @media (max-width: $device-width-tablet - 1px) {
      padding-right: 50px;
      background-position: right 10px center;
    }
  }

  .validated {
    background-color: $color-state-validated;
  }

  .cell-damage-report-no {
    width: 70px;
  }

  .cell-damages {
    width: 250px;

    .damage-template-id {
      display: inline-block;
      margin-right: 10px;
      line-height: 1.5em;
    }
  }

  .cell-wagon {
    width: 170px;

    .cell-wagon-no {
      font-weight: bold;
    }
  }

  .cell-wagon-second-line {
    display: flex;
    flex-basis: 100%;
    width: 100%;
    margin-top: 5px;
    font-size: 90%;
    color: $color-text-gray;

    .severe {
      color: $color-danger;
    }

    .cell-labels {
      padding-left: 30px;
      background-repeat: no-repeat;
      background-image: icon(labels, $color-text-gray);
      background-position: 10px -1px;

      .damage-label-ONE {
        color: $color-danger;
      }
    }

    .cell-company {
      width: 72px;
    }
  }

  .cell-creation-date {
    width: 170px;
  }

  .cell-validated-date {
    width: 170px;
  }

  .cell-created-by {
    width: 170px;
  }

  .cell-status {
    text-align: right;
    margin-left: auto;
  }
}

.cell-warning {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 60px;
  width: 40px;
  display: flex;
  align-items: center;

  img {
    width: 15px;
  }
}
