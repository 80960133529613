@import 'bourbon', '../../../commons/styles/variables', '../../../commons/styles/z-index',
  '../../../commons/styles/icons';

.vehicles-list {
  flex: 1;
  min-width: $vehicles-min-width;
  padding: 12px;
  position: relative;
  overflow-y: auto;
  background-color: $color-dark;
  -webkit-overflow-scrolling: touch;
  transition: transform $base-animation-time;

  //noinspection CssOverwrittenProperties
  &-container {
    height: 100vh; /* Fallback for browser not supporting the "dvh" unit */
    height: 100dvh;
    width: 60%;
    display: flex;
    flex-direction: column;

    .topbar {
      justify-content: space-between;

      > .btn.btn-icon.btn-basic {
        min-width: unset;
        padding-left: 10px;
        padding-right: 40px;
        //noinspection CssInvalidPropertyValue
        background-position-x: right 10px;
      }

      > .btn-group {
        display: flex;
        flex-direction: row;
        gap: 5px;
      }
    }

    &.collapsed {
      width: $vehicles-min-width;
    }
  }

  &-zero::after {
    content: 'Aucun véhicule saisi';
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    min-width: 350px;
    height: 100px;
    padding: 140px 50px 0;
    margin-top: -100px;
    font-size: 150%;
    text-align: center;
    font-weight: 300;
    white-space: nowrap;
    color: rgba(#fff, 0.3);
    background: no-repeat icon(railway, rgba(255, 255, 255, 0.2)) 50% 50%;
    background-size: 100px 100px;

    .loader & {
      display: none;
    }
  }

  .wagons {
    position: relative;
    width: 100%;
    height: auto;
    margin: 12px 0;
  }

  /* Need complex selector to handle the ghost element when dragging */
  /* stylelint-disable-next-line selector-max-compound-selectors */
  .engine + .wagon,
  .engine + .wagon[style='visibility: hidden;'] + .wagon,
  .wagon + .engine {
    margin-top: 12px;
  }

  @media (max-width: $device-width-tablet - 1px) {
    &-container {
      width: 100%;

      &.collapsed {
        width: unset;
        height: $vehicles-height-mobile + 24px;

        .vehicles-list {
          overflow: auto hidden;
          display: flex;
          flex-direction: row;

          .engine + .wagon,
          .engine + .wagon[style='visibility: hidden;'] + .wagon,
          .wagon + .engine {
            margin-top: 0;
            margin-left: 12px;
          }
        }

        .composition.pre-advice & {
          height: $vehicles-height-mobile-preadvice + 24px;
        }
      }
    }
  }
}

.engine-sort {
  .wagons {
    height: $vehicles-height + 30px;
  }

  .wagon {
    position: absolute;
    display: none;
  }

  @for $i from 1 to 6 {
    .wagon:nth-child(#{$i}) {
      z-index: #{5 - $i};
      display: block;
      top: #{5 * ($i - 1) + 'px'};
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
    }
  }
}
