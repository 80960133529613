@import 'bourbon', '../../../commons/styles/variables', '../../../commons/styles/icons';

.checkbox {
  display: inline-block;
  position: relative;
  padding: 12px 0 12px 35px;
  margin: 0 35px 0 0;
  min-height: 44px;
  cursor: pointer;
  white-space: pre-line;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: text;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    margin-top: -10px;
    left: 0;
    width: 20px;
    height: 20px;
    border-radius: calc($base-border-radius / 2);
  }
  &::before {
    border: 2px solid $color-gray;
  }
  &:hover::before {
    border-color: $color-accent;
  }

  &::after {
    opacity: 0;
    transform: scale(0.5) translate3d(0, 0, 0);
    transition-property: transform, opacity;
    transition-duration: calc($base-animation-time / 2);
    background: $color-accent icon(checkmark, rgba(255, 255, 255, 0.99)) 50% 50% no-repeat;
  }

  &.on::after {
    opacity: 1;
    transform: scale(1);
  }

  &.partial::before {
    border-color: rgba($color-accent, 0.5);
    background-color: rgba($color-accent, 0.5);
  }

  &.disabled-checkbox {
    cursor: default;
    &::before {
      border-color: $base-border-color;
      background-color: rgba($color-dark, 0.05);
    }
    &::after {
      background-color: #c9cbd0;
    }
    &.partial::before {
      background-color: #c9cbd0;
    }
  }
}
