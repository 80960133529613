@import 'bourbon', '../../commons/styles/variables', '../../commons/styles/icons';

//noinspection CssOverwrittenProperties
.composition-form {
  flex: 1;
  height: 100vh; /* Fallback for browser not supporting the "dvh" unit */
  height: 100dvh;
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  overflow: hidden;
  transition: opacity $base-animation-time;

  &-inner {
    flex: 1;
    padding: 0 10px 20px;
    display: flex;
    flex-flow: column nowrap;
    position: relative;
    overflow-y: auto;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;

    .spacer {
      flex: 1;
    }
  }

  > .topbar {
    > .title {
      > .btn {
        display: inline-block;
        margin: 0;
        min-width: unset;
      }
    }

    .title-actions .btn {
      min-width: unset;
      padding-left: 40px;
      padding-right: 10px;
      background-position-x: 10px;
    }
  }

  .step-summary {
    padding-left: 10px;

    .header {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      margin-bottom: 8px;
    }

    .train-no {
      font-size: 80%;
      font-weight: 900;
      text-transform: uppercase;
      letter-spacing: 2px;
      color: #fff;
      background-color: $color-gray;
      padding: 3px 5px;
      border-radius: calc($base-border-radius / 2);
      text-align: center;
      width: 105px;
      margin-right: 35px;
    }

    .route {
      font-weight: bold;
    }
  }

  .composition-summary {
    &.validated {
      background-color: $color-state-validated;
      border-color: darken($color-state-validated, 15);
    }
  }

  .copied-message,
  .info-message {
    font-size: 75%;
    font-style: italic;
    margin-top: 8px;
  }

  .validated-train {
    padding: 15px 0 15px 90px;
    position: relative;
    font-size: 150%;
    font-weight: 700;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 60px;
      height: 60px;
      border-radius: 30px;
      background: $color-success icon(checkmark, rgba(255, 255, 255, 0.99)) 50% 50% no-repeat;
      background-size: 30px 30px;
    }
  }

  .btn-full-width {
    background-position: 20px 50%;
    background-repeat: no-repeat;
    background-image: icon(unlocked, rgba(255, 255, 255, 0.99));
  }

  .form-section {
    margin-bottom: 15px;
    padding: 10px;
    background-color: #ffffff;

    > .title {
      margin: 0;
      font-size: 125%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    > .content {
      > .btn {
        margin: 10px 0 0;
      }

      > .buttons {
        margin: 10px 0 0;
        display: flex;

        .btn {
          margin: 0 20px 0 0;
        }
      }
    }
  }
}
