@import '~normalize.css/normalize.css', 'bourbon', 'variables', 'offline', 'z-index', 'fonts', 'button', 'loader',
  'modal', 'datetime', 'table', 'hazmat';

*,
*::after,
*::before {
  box-sizing: border-box;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

html {
  height: 100%;
  overflow: hidden;
  font: 16px 'Lato', sans-serif;
}

body {
  min-height: 100%;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  color: $color-text-default;
  font: 16px 'Lato', sans-serif;
  font-weight: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

@media (max-width: $base-content-min-width - 1px) {
  body {
    scroll-behavior: smooth;
    overflow: auto;
  }
}

a {
  text-decoration: none;
  color: inherit;
}

.text-gray {
  color: $color-text-gray;
}
.text-danger {
  color: $color-danger;
}
.text-neutral {
  color: $color-neutral;
}
.text-success {
  color: $color-success;
}
