.loader {
  width: 100%;
  height: 100%;
  pointer-events: none;
  position: relative;
  z-index: 100;

  &::after {
    border: 3px solid $color-accent;
    border-left-color: transparent;
    border-top-color: transparent;
    border-radius: 50%;
    content: '';
    display: block;
    width: 34px;
    height: 34px;
    margin-top: -17px;
    margin-left: -17px;
    position: absolute;
    top: 50%;
    left: 50%;
    @include z-index(loader);
    transform: translate(0, 0);
    animation: spinner 1.5 * $base-animation-time infinite linear;
  }

  &.small::after {
    width: 24px;
    height: 24px;
    margin: -12px 0 0 -12px;
    position: absolute;
    border-width: 2px;
  }

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.88);
    @include z-index(loader);
  }
}

@keyframes spinner {
  0% {
    transform: (rotate(0deg));
  }
  100% {
    transform: (rotate(360deg));
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
