@import '../../../commons/styles/variables', '../../../commons/styles/icons';

.table-users-profiles {
  border-top: 1px solid $base-border-color;

  .cell-profile-title {
    width: 100%;
    font-size: 120%;
    font-weight: bold;
  }

  .cell-profile-roles {
    display: flex;
    flex-flow: row wrap;
    margin-top: 10px;
  }

  .role {
    width: 33%;
    padding: 2px 10px 2px 20px;
    font-size: 90%;
    color: $color-gray;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background: icon(close, rgba($color-gray, 0.35)) 0 50% no-repeat;
    background-size: 10px 10px;

    @media (max-width: $device-width-tablet - 1px) {
      width: 50%;
    }
  }

  .role.on {
    color: $color-text-default;
    background-image: icon(checkmark, rgba($color-success, 0.99));
    background-position: -2px 50%;
    background-size: 15px 15px;
  }
}
