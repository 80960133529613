@import 'bourbon', '../../../commons/styles/variables', '../../../commons/styles/icons',
  '../../../commons/styles/offline';

.table.search-results {
  position: absolute;
  min-width: $overlay-min-width;
  width: 100%;
  height: calc(100% - 374px);
  padding: 0;
  margin: $base-button-height 0 0 -20px;

  .search-results-nav {
    position: absolute;
    width: 100%;
    margin: 0;
    padding: 0;
    height: $base-button-height;
    text-align: center;
    color: $color-accent;
    cursor: pointer;
    background-color: #fff;
    &:hover {
      background-color: rgba($color-accent, 0.1);
    }

    span {
      display: inline-block;
      padding-left: 30px;
      background-position: 0 50%;
      background-repeat: no-repeat;
    }
  }

  .search-results-nav.prev {
    top: -$base-button-height;
    border-bottom: 2px solid $base-border-color;
    span {
      background-image: icon(arrow-up, rgba($color-accent, 0.99));
    }
  }

  .search-results-nav.next {
    bottom: -$base-button-height;
    border-top: 2px solid $base-border-color;
    span {
      background-image: icon(arrow-down, rgba($color-accent, 0.99));
    }
  }

  .no-result,
  .offline {
    @extend %offline;
  }

  .table-body-row {
    padding: 15px 70px 20px 20px;
    background: $color-state-empty url('../../assets/images/icon-comp-state-empty.svg') right 20px center no-repeat;

    &.not-validated {
      background-color: $color-state-not-validated;
      background-image: url('../../assets/images/icon-comp-state-not-validated.svg');
    }

    &.validated {
      background-color: $color-state-validated;
      background-image: url('../../assets/images/icon-comp-state-validated.svg');

      &.received-pre-advice {
        background-color: $color-state-received-pre-advice-background;
        background-image: url('../../assets/images/icon-pre-advice_received-not-used.svg');
        &.received-pre-advice-used {
          background-image: url('../../assets/images/icon-pre-advice_received-used.svg');
        }
      }
    }

    &.pre-advice .cell-type {
      background-image: icon(preadvice, $color-gray);
    }

    &.train .cell-type {
      background-image: icon(engine, $color-gray);
    }
  }

  .cell-route {
    width: 100%;
    padding-left: 30px;
    font-weight: 300;
    margin-top: 5px;
  }
  .cell-route-from {
    display: inline;
  }
  .cell-route-to {
    display: inline;
  }

  .cell-route-to::before {
    content: '\002794';
    padding: 0 5px;
    display: inline-block;
    font-family: Meiryo, sans-serif;
  }

  .cell-type {
    width: 150px;
    height: 20px;
    margin-right: 10px;
    line-height: 20px;
    font-weight: bold;
    font-size: 75%;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: $color-text-gray;
    padding-left: 30px;
    background-repeat: no-repeat;
    background-position: 0 50%;
    background-size: 15px 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .cell-date {
    width: 120px;
    height: 20px;
    margin-right: 10px;
    line-height: 20px;
    font-size: 90%;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .cell-contract,
  .cell-sender-receiver {
    width: calc(100% - 130px - 160px);
    color: $color-text-gray;
    height: 20px;
    line-height: 20px;
    font-size: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.input {
  input.composition-import-train-number {
    background-repeat: no-repeat;
    background-position: 10px 12px;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" height="50px" width="120px"><text x="0" y="15" fill="#{$color-gray}" font-size="20" font-family="Lato,Helvetica">N°</text></svg>');
    padding-left: 40px;
  }
}
