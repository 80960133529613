.user-details {
  &-activation-switch {
    position: absolute;
    top: 15px;
    right: 0;
  }

  .btn.refresh {
    margin-right: auto;
    margin-left: 0;
  }

  .overlay-form .input-dropdown {
    margin: 10px 0;
  }
}
