@import '../../../commons/styles/variables';

.prefixed-field {
  position: relative;

  .prefixed-field-prefix {
    position: absolute;
    width: 40px;
    height: $base-button-height;
    line-height: $base-button-height;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    font-weight: bold;
    color: $color-gray;
    border-radius: $base-border-radius 0 0 $base-border-radius;
    z-index: 1;
  }

  input {
    padding-left: 40px;
  }
}
