@import 'bourbon', '../../../commons/styles/variables', '../../../commons/styles/icons';

.switch3 {
  position: relative;
  margin: 10px 0;
  display: flex;
  align-items: center;

  &.disabled {
    pointer-events: none;
  }

  label.switch3-label {
    font-weight: normal;
    display: inline-block;
    margin: 0;
    padding: 0 12px;
    text-align: right;
    font-size: 85%;
    cursor: pointer;
    white-space: unset;
    .disabled & {
      cursor: default;
      color: rgba($color-dark, 0.2);
    }
    &:nth-child(1) {
      padding-left: 0;
    }
    &:last-child {
      text-align: left;
    }
  }

  &-root {
    position: relative;
    display: inline-block;
    opacity: 1;
    border-radius: 20px;
    transition: opacity 0.25s;
    touch-action: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    user-select: none;
    vertical-align: middle;

    .disabled & {
      opacity: 0.5;
    }
  }

  &-bg {
    height: 40px;
    width: 96px;
    margin: 0;
    position: relative;
    display: block;
    background-color: rgba($color-dark, 0.05);
    border-radius: 20px;
    transition: background 0.25s;

    .switch3:hover & {
      background-color: rgba($color-accent, 0.1);
    }
    .on & {
      background-color: $color-accent;
    }
    .on:hover & {
      background-color: darken($color-accent, 5);
    }
  }

  &-button {
    position: relative;
    width: 32px;
    height: 40px;
    cursor: pointer;

    .disabled & {
      cursor: default;
    }
  }

  &-handle {
    height: 34px;
    width: 34px;
    background-color: #fff;
    background-position: 50% 47%;
    background-repeat: no-repeat;
    background-size: 17px 17px;
    cursor: pointer;
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    top: 3px;
    outline: 0;
    border: 0;
    transition: background-color 0.25s, transform 0.25s, box-shadow 0.15s;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.11), 0 4px 8px 0 rgba(0, 0, 0, 0.11);

    &.outline {
      box-shadow: 0 0 2px 3px #3bf;
    }
    .disabled & {
      cursor: default;
    }
    .lock & {
      background-image: icon(unlocked, $color-gray);
    }
    .on.lock & {
      background-image: icon(locked, $color-gray);
    }
  }

  &-content {
    position: relative;
    display: inline-block;
    width: 96px;
    height: 40px;
    border-radius: 20px;
    background-color: rgba($color-dark, 0.05);
    margin: 0;
    &:hover {
      background-color: rgba($color-accent, 0.1);
    }
    button {
      cursor: pointer;
    }
  }

  &-content::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 96px;
    height: 40px;
    border-radius: 20px;
    background-color: $color-success;
    opacity: 0;
    transition: opacity calc($base-animation-time / 2);

    .left &,
    .right & {
      opacity: 1;
    }

    .input-error & {
      background-color: $color-danger;
      opacity: 0.5;
    }
  }

  &-content::after {
    content: '';
    position: absolute;
    top: 3px;
    left: 31px;
    width: 34px;
    height: 34px;
    border-radius: 17px;
    background-color: #fff;
    background-position: 50% 47%;
    background-repeat: no-repeat;
    background-size: 17px 17px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.11), 0 4px 8px 0 rgba(0, 0, 0, 0.11);
    transition: transform calc($base-animation-time / 2);
    .left & {
      transform: translate(-27px, 0);
    }
    .right & {
      transform: translate(27px, 0);
    }
    .disable & {
      box-shadow: none;
    }
  }

  &-btn {
    opacity: 0;
    width: 32px;
    height: 40px;
  }
}
