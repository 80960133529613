@import 'bourbon', '../../../commons/styles/variables', '../../../commons/styles/z-index';

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  @include z-index(overlay);

  &-fade {
    background-color: rgba($color-dark, 0.7);
    height: 100%;
    width: 100%;
    opacity: 1;
    position: fixed;
    transform: translate3d(0, 0, 0);
    animation: overlay-fade-on $base-animation-time;
    transition: opacity calc($base-animation-time / 3);
    .hide & {
      opacity: 0;
    }
  }

  &-body {
    background-color: #fff;
    color: $color-text-default;
    position: absolute;
    top: 0;
    right: 0;
    padding: 20px 20px 0;
    width: 100%;
    max-width: $overlay-max-width;
    min-width: $overlay-min-width;
    height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    transform: translate3d(0, 0, 0);
    animation: overlay-body-on $base-animation-time;
    transition-property: opacity, transform;
    transition-duration: calc($base-animation-time / 3);
    .hide & {
      transform: translate($overlay-max-width, 0);
    }

    > .btn.close {
      position: absolute;
      top: 12px;
      right: 20px;
    }
  }

  &-form {
    display: flex;
    flex-flow: column;
    height: calc(100% - 50px);
  }

  &-body > form {
    height: calc(100% - 50px);

    .inline-button {
      margin-top: 20px;
    }

    .btn {
      margin: 0;
    }

    > .overlay-form {
      height: 100%;
    }
  }

  &-form .inputs-group {
    .input {
      vertical-align: top;
      display: inline-block;
      width: calc(50% - 10px);
    }

    .input:first-child {
      margin-right: 20px;
    }

    .checkboxes > .checkbox {
      width: calc(50% - 10px);

      @media (max-width: $device-width-tablet - 1px) {
        width: 100%;
      }
    }
  }

  &-form .checkboxes {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    > .label,
    > .error-message {
      width: 100%;
    }

    > .checkbox {
      margin: 0;
      width: 100%;
    }
  }

  &-form .label {
    font-size: 85%;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: calc($base-button-height / 2);
    line-height: calc($base-button-height / 2);
    user-select: text;
  }

  &-form .input-tags {
    margin-bottom: 30px;
  }

  &-form .checkbox {
    padding-top: 8px;
    padding-bottom: 8px;
    min-height: 36px;
  }

  &-form .btn-group {
    display: flex;
    flex-flow: row-reverse wrap-reverse;
    gap: 5px;
    margin-top: auto;
    padding: 40px 0 20px;

    .btn {
      min-width: unset;
    }

    .btn.remove {
      margin-right: auto;
    }
  }

  .public_fixedDataTable_main {
    margin-left: -20px;
  }

  .loader {
    &::before {
      top: 0;
      left: 0;
    }

    &::after {
      position: absolute;
      margin: -17px 0 0 -17px !important;
    }
  }

  &-title {
    margin: 0 0 20px;
    font-size: 150%;
    font-weight: bold;
    padding: 0;
    user-select: text;
  }

  &-copy-wagons {
    .overlay-body {
      display: flex;
      flex-direction: column;
      padding: 20px 0 0;

      .overlay-title,
      .fields {
        padding: 0 20px;
      }

      form {
        height: auto;
      }

      .table.search-results {
        margin: 0;
        position: static;
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        .table-body {
          padding: 0;
        }

        button,
        .no-result {
          position: static;
        }
      }
    }
  }
}

@keyframes overlay-fade-on {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes overlay-body-on {
  0% {
    opacity: 0;
    transform: translate3d(calc($overlay-max-width / 2), 0, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
