.pre-advice.composition {
  .composition-form-inner {
    padding-left: 20px;
    padding-right: 20px;

    .pre-advice-read-only-field,
    form {
      flex-grow: 1;
      ul.list {
        flex-grow: 0;
      }
    }
  }

  .unknown-pre-advice-station {
    background-image: url('../../commons/assets/images/warning.svg');
    background-position: center right;
    background-repeat: no-repeat;
    padding-right: 15px;
  }
}
