.pre-advice-wagon-import-errors {
  padding: 10px 10px 10px 40px;
  border-radius: 5px;
  margin-top: 10px;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: 10px 50%;

  &.pre-advice-wagon-info {
    background-color: #abd4e8;
    background-image: url('../../../commons/assets/images/info.svg');
  }

  &.pre-advice-wagon-warning {
    background-color: #fffae6;
    background-image: url('../../../commons/assets/images/warning.svg');
  }

  .error-pictogram {
    flex-basis: 20px;
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 10px;
  }

  ul {
    margin-top: 5px;
  }
}
