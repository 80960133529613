@import 'bourbon', '../../../commons/styles/variables', '../../../commons/styles/z-index';

.app-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  overflow: hidden;
  background-color: #fff;
  @include z-index(content);
  &.expanded {
    margin-left: $sidebar-min-width;
  }
  &.collapsed {
    margin-left: $sidebar-max-width;
  }

  .title {
    cursor: default;
    font-size: 150%;
    font-weight: bold;
    padding: 0;

    > .loader {
      width: 34px;
      float: right;
    }
  }

  .subtitle {
    cursor: default;
    font-size: 120%;
    font-weight: bold;
    padding: 0;
  }

  @media (max-width: $device-width-tablet - 1px) {
    z-index: unset;
  }
}

.topbar {
  display: flex;
  flex-flow: row nowrap;
  margin-right: 10px;
  align-items: center;
  height: 72px;
  position: relative;
  background-color: #fff;
  @include z-index(topbar);

  @media (max-width: $device-width-tablet - 1px) {
    padding-left: 60px;
  }

  .title {
    flex-grow: 1;
    margin: 0 8px 0 0;
    padding: 20px 0 20px 20px;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    &::after {
      // overflow gradient
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 40px;
      background-image: linear-gradient(to left, rgba(255, 255, 255, 255), rgba(255, 255, 255, 0));
    }
    @media (max-width: $device-width-tablet - 1px) {
      padding-left: 0;
    }
  }

  .filters > form > .input {
    width: 160px;
    margin: 0 5px;
    transition: width $base-animation-time;

    @media (max-width: $device-width-tablet - 1px) {
      width: 80px;
      margin: 0;

      &:focus-within {
        width: 160px;
      }
    }
  }

  > .loader {
    width: 100px;
  }

  > .checkbox {
    margin: 0 5px;
  }

  @include z-index(topbar);
}

.maincontent {
  margin: 0 20px;
  height: calc(100% - 72px);
}

.content-parent {
  height: 100%;

  &.table-page {
    display: flex;
    flex-direction: column;

    .table {
      flex-grow: 1;
      padding-bottom: 0;
      display: flex;
      flex-direction: column;
      overflow-y: hidden;

      .table-header {
        flex-grow: 0;
        flex-shrink: 0;
      }

      .table-body {
        flex-grow: 1;
        overflow-y: scroll;
        padding-bottom: 0;
      }
    }
  }
}
