@import 'bourbon', '../../../commons/styles/variables', '../../../commons/styles/icons',
  '../../../commons/styles/z-index';

.filters {
  position: relative;
  margin-left: 8px;

  .btn.filter {
    margin: 0 8px;
  }

  > form {
    display: flex;
    align-items: center;
  }

  &-dropdown {
    position: absolute;
    top: $base-button-height + 10px;
    right: 20px;
    width: 390px;
    // Allow margins on the left and right when the viewport width is reduced
    max-width: calc(100% - 40px);
    padding: 10px 20px 20px;
    // Allow margin on the bottom, accounting for the top position
    max-height: calc(100% - $base-button-height - 10px - 20px);
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    background-color: #fff;
    border-radius: $base-border-radius;
    box-shadow: 0 20px 80px rgba(0, 0, 0, 0.2);
    @include z-index(dropdown);
    animation: dropdown-on calc($base-animation-time / 2);

    .input {
      width: 100%;
      margin: 0;
    }

    .rdt,
    .input-tags {
      margin: 10px 0;

      input {
        margin: 0;
      }

      .tag {
        margin-bottom: 0;
      }
    }

    .rdtPicker,
    .dropdown-container {
      width: calc(100% + 40px);
      margin: 10px 0 0 -20px;
      position: relative;
      box-shadow: none;
      border-radius: 0;
      background-color: rgba($color-gray, 0.07);
    }

    .dropdown-body.loader {
      position: relative;
    }

    .date-interval .rdtPicker {
      // No transparency - only works on a white background
      background-color: #efeff1;
      width: calc(100% + 90px);
      box-shadow: #ffffff 0 0 2px 8px;
    }
  }

  .date {
    position: relative;
    margin: 10px 0;

    &-label {
      position: absolute;
      width: 40px;
      height: $base-button-height;
      line-height: $base-button-height;
      display: inline-block;
      vertical-align: top;
      text-align: center;
      font-weight: bold;
      color: $color-gray;
      border-radius: $base-border-radius 0 0 $base-border-radius;
      z-index: 1;
    }

    .rdt {
      margin: 0;
      width: 100%;
      display: inline-block;
      vertical-align: top;
    }

    input {
      padding-left: 40px;
    }
  }

  .btn-group {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin: 10px -5px 20px;

    .btn {
      flex: 1;
      padding: 35px 4px 0;
      margin: 0 5px;
      height: 2 * $base-button-height;
      line-height: normal;
      font-size: 90%;
      color: $color-text-gray;
      border: 1px solid $base-border-color;
      background-position: 50% 20px;
      background-repeat: no-repeat;
      min-width: unset;
    }

    .btn:disabled {
      background-color: rgba($color-dark, 0.05);
    }

    .btn.state-1 {
      background-image: url('../../assets/images/icon-comp-state-empty.svg');
    }

    .btn.state-2 {
      background-image: url('../../assets/images/icon-comp-state-not-validated.svg');
    }

    .btn.state-3 {
      background-image: url('../../assets/images/icon-comp-state-validated.svg');
    }

    .btn.on {
      color: $color-accent;
      border-color: $color-accent;
    }
  }

  .date-interval {
    display: flex;
    margin: 10px 0;

    .dates {
      flex: 1;
    }

    .date {
      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    > .btn {
      margin: 0 0 0 10px;
      height: 98px;
      width: 40px;
      min-width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      > div {
        transform: rotate(90deg);
      }
    }
  }

  .btn.only-mine {
    color: $color-text-gray;

    &.on {
      color: $color-accent;
      border-color: $color-accent;
    }
  }
}

@keyframes filters-dropdown-on {
  0% {
    opacity: 0;
    transform: translate(0, 20px);
  }

  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}
