@import 'bourbon', '../../../commons/styles/variables', '../../../commons/styles/icons';

.damage-report-form {
  padding-bottom: 20px;

  .flex {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: space-between;

    .input {
      flex-shrink: 1;
      width: 49%;
    }
    .input.rdt {
      width: 100%;
    }
  }

  .btn {
    margin: 0;
  }

  .btn-signature {
    width: 100%;
    margin-top: 26px;

    &.offline {
      font-style: italic;
      color: $color-text-gray;
    }
  }

  .signature {
    .column {
      width: 100%;

      &.buttons {
        margin-top: 20px;
      }
    }

    div.signature-image-container {
      width: 100%;
      padding: 10px;
      box-sizing: border-box;
      border-radius: $base-border-radius;
      border: 1px solid $base-border-color;
      text-align: center;
    }
  }

  .author {
    text-transform: capitalize;
    padding: 10px 0;
  }
}
