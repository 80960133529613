@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  src: local('Lato Regular'), local('Lato-Regular'), url('../assets/fonts/Lato-Regular-Ex.woff2') format('woff2'), url('../assets/fonts/Lato-Regular-Ex.woff') format('woff');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  src: local('Lato Regular'), local('Lato-Regular'), url('../assets/fonts/Lato-Regular.woff2') format('woff2'), url('../assets/fonts/Lato-Regular.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: bold;
  src: local('Lato Bold'), local('Lato-Bold'), url('../assets/fonts/Lato-Bold-Ex.woff2') format('woff2'), url('../assets/fonts/Lato-Bold-Ex.woff') format('woff');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: bold;
  src: local('Lato Bold'), local('Lato-Bold'), url('../assets/fonts/Lato-Bold.woff2') format('woff2'), url('../assets/fonts/Lato-Bold.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: local('Lato Light'), local('Lato-Light'), url('../assets/fonts/Lato-Light.woff2') format('woff2'), url('../assets/fonts/Lato-Light.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: local('Lato Black'), local('Lato-Black'), url('../assets/fonts/Lato-Black-Ex.woff2') format('woff2'), url('../assets/fonts/Lato-Black-Ex.woff') format('woff');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: local('Lato Black'), local('Lato-Black'), url('../assets/fonts/Lato-Black.woff2') format('woff2'), url('../assets/fonts/Lato-Black.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
