@import '../../../commons/styles/variables';

.uti {
  width: 100%;
}

.uti-row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  > div {
    margin-right: 8px;
    flex-basis: 48%;

    &.checkbox {
      flex-basis: unset;
    }
  }
}

.uti-field-container {
  display: flex;
  flex-direction: row;
  > * {
    margin-right: 8px;
  }
}

.uti-value {
  margin-top: 5px;
  min-width: 36px;

  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
}

.uti-button {
  cursor: pointer;
  height: 34px;
  width: 34px;
  padding: 0;
  border-radius: 50%;
  border: solid medium $color-accent;

  text-align: center;
  color: $color-accent;
  font-weight: bolder;
  font-size: 24px;
  line-height: 24px;

  &:hover {
    background-color: rgba($color-accent, 0.15);
  }
  &:active {
    background-color: rgba($color-accent, 0.3);
  }
  &:disabled {
    color: $color-button-disabled;
    border-color: $color-button-disabled;
    background-color: #ffffff;
  }
}
