@import 'bourbon', '../../../commons/styles/variables', '../../../commons/styles/icons';

@function encode-color($string) {
  @if type-of($string) == 'color' {
    $hex: str-slice(ie-hex-str($string), 4);
    $string: unquote('#{$hex}');
  }
  $string: '%23' + $string;
  @return $string;
}

@function train-message-icon($color1, $color2, $color3, $opacity1, $opacity2, $opacity3, $css) {
  $icon: '%3Csvg%20version=%221.1%22%20xmlns=%22http://www.w3.org/2000/svg%22%20x=%220%22%20y=%220%22%20width=%2248%22%20height=%2210%22%20viewBox=%220%200%20384%2096%22%3E%3Cpath%20d=%22M120,0 L120,96 L48,96 C21.49,96 0,74.51 0,48 C0,21.49 21.49,0 48,0 L120,0 z%22%20style%3D%22#{$css}%22%20fill-opacity%3D%22#{$opacity1}%22%20fill=%22#{encode-color($color1)}%22/%3E%3Cpath%20d=%22M132,0 L252,0 L252,96 L132,96 L132,0 z%22%20fill-opacity%3D%22#{$opacity2}%22%20fill=%22#{encode-color($color2)}%22/%3E%3Cpath%20d=%22M264,96 L264,0 L336,0 C362.51,0 384,21.49 384,48 C384,74.51 362.51,96 336,96 L264,96 z%22%20fill-opacity%3D%22#{$opacity3}%22%20fill=%22#{encode-color($color3)}%22/%3E%3C/svg%3E';
  @return url('data:image/svg+xml;charset=utf8,#{$icon}');
}

.table.table-sent-messages {
  .table-body-row {
    cursor: default;
    font-size: 90%;
    padding: 20px;

    &.row-creating .cell-status {
      color: $color-text-gray;
    }

    &.row-sending .cell-status {
      color: $color-text-gray;
    }

    &.row-error .cell-status {
      color: $color-danger;
    }
    &.row-error {
      background-color: lighten($color-danger, 37);
    }

    &.row-sent .cell-status,
    &.row-sent-incomplete .cell-status {
      color: $color-text-gray;
    }

    .cell-status {
      padding-left: 50px;
      background-position: 0 50%;
      background-repeat: no-repeat;
    }

    .cell-action {
      justify-content: space-between;

      .message-resent {
        width: 25px;
        height: 25px;
        background-image: icon(checkmark, rgba($color-success, 0.99));
        background-position: -2px 50%;
        background-size: 25px 25px;
      }
    }

    .train-number-date-market-statut-locations {
      display: flex;
      flex-direction: column;
      flex: 2 2 655px;
      @media (max-width: $device-width-desktop - 1px) {
        flex: 2 2 505px;
      }

      .train-number-date-market-statut {
        align-items: center;
        display: flex;
      }

      .cell-locations {
        margin-top: 5px;
      }

      .cell-route {
        font-size: 0.95em;
      }

      .cell-problem {
        margin-top: 4px;
        font-size: 90%;
        display: flex;
        align-items: center;
        img {
          margin-right: 4px;
          width: 12px;
        }
      }
    }

    $color-empty: 'black';
    /*
     * Empty composition
     */
    .cell-status.composition-empty {
      /*
       * Message successfully sent to the IC
       */
      &.message-sending-success {
        /*
         * Message successfully treated by the IC.
         */
        &.message-response-success {
          background-image: train-message-icon($color-empty, $color-success, $color-success, 0.1, 1, 1, '');
        }

        /*
         * IC returned an error for the message.
         */
        &.message-response-error {
          background-image: train-message-icon($color-empty, $color-success, $color-danger, 0.1, 1, 1, '');
        }

        /*
         * Waiting for the IC response
         */
        &.message-response-waiting {
          background-image: train-message-icon($color-empty, $color-success, $color-neutral, 0.1, 1, 1, '');
        }
      }

      /*
       * Error while sending message to the IC
       */
      &.message-sending-error {
        background-image: train-message-icon($color-empty, $color-danger, $color-empty, 0.1, 1, 0.1, '');
      }

      /*
       * Currently sending the message to the IC
       */
      &.message-sending-in-progress {
        background-image: train-message-icon($color-empty, $color-neutral, $color-empty, 0.1, 1, 0.1, '');
      }
    }

    /*
     * Modified composition but not validated
     */
    .cell-status.not-validated {
      /*
       * Message successfully sent to the IC
       */
      &.message-sending-success {
        /*
         * Message successfully treated by the IC.
         */
        &.message-response-success {
          background-image: train-message-icon($color-neutral, $color-success, $color-success, 1, 1, 1, '');
        }

        /*
         * IC returned an error for the message.
         */
        &.message-response-error {
          background-image: train-message-icon($color-neutral, $color-success, $color-danger, 1, 1, 1, '');
        }

        /*
         * Waiting for the IC response
         */
        &.message-response-waiting {
          background-image: train-message-icon($color-neutral, $color-success, $color-neutral, 1, 1, 1, '');
        }
      }

      /*
       * Error while sending message to the IC
       */
      &.message-sending-error {
        background-image: train-message-icon($color-neutral, $color-danger, $color-empty, 1, 1, 0.1, '');
      }

      /*
       * Currently sending the message to the IC
       */
      &.message-sending-in-progress {
        background-image: train-message-icon($color-neutral, $color-neutral, $color-empty, 1, 1, 0.1, '');
      }
    }

    /*
     * Validated composition
     */
    .cell-status.validated {
      /*
       * Message successfully sent to the IC
       */
      &.message-sending-success {
        /*
         * Message successfully treated by the IC.
         */
        &.message-response-success {
          background-image: train-message-icon($color-success, $color-success, $color-success, 1, 1, 1, '');
        }

        /*
         * IC returned an error for the message.
         */
        &.message-response-error {
          background-image: train-message-icon($color-success, $color-success, $color-danger, 1, 1, 1, '');
        }

        /*
         * Waiting for the IC response
         */
        &.message-response-waiting {
          background-image: train-message-icon($color-success, $color-success, $color-neutral, 1, 1, 1, '');
        }
      }

      /*
       * Error while sending message to the IC
       */
      &.message-sending-error {
        background-image: train-message-icon($color-success, $color-danger, $color-empty, 1, 1, 0.1, '');
      }

      /*
       * Currently sending the message to the IC
       */
      &.message-sending-in-progress {
        background-image: train-message-icon($color-success, $color-neutral, $color-empty, 1, 1, 0.1, '');
      }
    }
  }

  .cell-train-no {
    width: 85px;
    margin-right: 20px;
  }

  .cell-date {
    width: 130px;
  }

  .cell-market {
    flex-basis: 200px;

    @media (max-width: $device-width-desktop - 1px) {
      display: none;
    }
  }

  .cell-composition {
    width: 60px;
    height: 20px;
    background-repeat: no-repeat;
    background-position: 0 50%;
  }

  .cell-status {
    flex: 2 2 200px;
    margin-right: 10px;
  }

  .cell-attempts {
    flex: 0 0 60px;
    margin-right: 10px;
    white-space: nowrap;
    @media (max-width: $device-width-desktop - 1px) {
      flex: 0 0 50px;
    }
  }
  .success {
    color: $color-success;
  }
  .fail {
    color: $color-neutral;
  }

  .cell-action {
    display: flex;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 35px;

    .checkbox {
      margin: 0;
    }
  }

  .table-header {
    .cell-action {
      justify-content: space-between;
      .checkbox {
        height: 30px;
        min-height: 30px;
      }
    }
  }

  .resend-multiple-messages-btn {
    position: fixed;
    bottom: 20px;
    right: 65px;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14),
      0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }

  @media (max-width: $device-width-tablet - 1px) {
    .table-header {
      justify-content: flex-end;
      padding: 0;
      > :not(.cell-action) {
        display: none;
      }
    }

    .table-body-row {
      position: relative;
      padding: 10px 40px 10px 10px;

      .train-number-date-market-statut {
        flex-wrap: wrap;
        row-gap: 5px;
      }

      .cell-status {
        flex: 0 0 100%;
      }

      .cell-action {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        align-items: center;
      }

      .cell-attempts {
        position: absolute;
        right: 2px;
        top: 0;
        height: 38px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
      }
    }
  }
}
