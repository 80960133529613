@import 'bourbon', '../../commons/styles/variables';

.railway-company-label {
  position: absolute;
  width: 40px;
  height: $base-button-height;
  line-height: $base-button-height;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  font-weight: bold;
  color: $color-gray;
  border-radius: $base-border-radius 0 0 $base-border-radius;
  z-index: 1;
}
