@import '../../../commons/styles/variables';

.composition-form div.ppe-summary {
  .title {
    & + .btn {
      margin-top: 0;
    }
  }

  .ppe-summary-items {
    padding-top: 10px;
  }

  .copied-message {
    margin-top: -11px;
    margin-bottom: 10px;
  }

  .ppe-summary-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &:not(:last-child) {
      border-bottom: 1px solid $base-border-color;
    }

    & .list {
      margin: 0;
    }
  }

  .checkboxes {
    margin-bottom: 10px;
  }

  .checkbox {
    min-width: 33%;
    margin: 0;
    padding-left: 25px;
    padding-right: 10px;
    height: 24px;
    min-height: 24px;
    line-height: 24px;
  }
}
