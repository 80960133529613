@import
  '../../../commons/styles/variables';

.multi-checkboxes {
  .error {
    font-size: 85%;
    color: $color-danger;
    white-space: normal;
  }
}
