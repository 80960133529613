@import 'bourbon', '../../../commons/styles/variables', '../../../commons/styles/icons';

.table-mailing-lists {
  font-size: 90%;

  .table-body-row {
    &.inactive {
      color: $color-text-gray;
    }
  }

  .cell-name,
  .cell-mission-type {
    flex: 1;
  }

  .cell-markets,
  .cell-start-station,
  .cell-end-station,
  .cell-type {
    flex: 2;
  }

  .cell-emails {
    flex: 3;
  }

  .cell-attachments {
    width: 45px;
    display: flex;
    justify-content: space-around;

    .icon {
      width: 20px;
      height: 20px;
      &.pdf {
        background-image: icon(pdf, rgba($color-dark, 0.99));
      }
      &.xls {
        background-image: icon(xls, rgba($color-dark, 0.99));
      }
    }
  }

  @media (max-width: $device-width-desktop - 1px) {
    .cell-markets,
    .cell-start-station,
    .cell-end-station,
    .cell-mission-type {
      display: none;
    }

    .cell-name {
      flex: 2;
    }
  }

  @media (max-width: $device-width-tablet - 1px) {
    border-top: 1px solid $base-border-color-dark;
    margin-top: 8px;

    .table-header {
      display: none;
    }

    .table-body-row {
      position: relative;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: start;
      padding-right: 40px;
      row-gap: 5px;
    }

    .cell-name {
      font-weight: bold;
    }

    .cell-type {
      flex: 0;
      white-space: nowrap;
    }

    .cell-emails {
      flex: 0 0 100%;
    }

    .cell-attachments {
      position: absolute;
      right: 10px;
      top: 10px;
      bottom: 10px;
      flex-direction: column;
      justify-content: center;
      gap: 5px;
      width: 20px;
    }
  }
}
