@import 'bourbon', '../../commons/styles/variables';

.composition {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &.expanded {
    @media (max-width: $device-width-tablet - 1px) {
      flex-direction: column-reverse;
    }
  }
}
