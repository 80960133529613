@import 'bourbon', '../../../commons/styles/variables', '../../../commons/styles/icons';

.input-tags {
  width: 100%;

  .tags {
    overflow: hidden;
  }

  .tag {
    display: inline-block;
    color: #fff;
    font-size: 90%;
    height: 24px;
    line-height: 24px;
    margin: 0 5px 5px 0;
    padding: 0 10px;
    border-radius: 12px;
    background: $color-gray 8px 50% no-repeat;
    background-size: 12px 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.inactive {
      background-color: rgba($color-gray, 0.2);
    }

    &:not(.disabled):hover,
    &:not(.disabled).inactive:hover {
      background-color: darken($color-gray, 15);
    }

    &:not(.disabled) {
      background-image: icon(close, rgba(255, 255, 255, 0.99));
      padding-left: 25px;
      cursor: pointer;
    }
  }
}
