.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  @include z-index(modal);

  &-fade {
    background-color: rgba($color-dark, 0.7);
    height: 100%;
    width: 100%;
    opacity: 1;
    position: fixed;
    animation: modal-fade-on 2 * $base-animation-time;
    transition: opacity calc($base-animation-time / 3);
    .hide & {
      opacity: 0;
    }
  }

  &-body {
    background-color: #fff;
    color: $color-text-default;
    position: fixed;
    left: 50%;
    top: 50%;
    overflow: hidden;
    width: $modal-min-width;
    box-shadow: 0 20px 80px rgba(0, 0, 0, 0.4);
    border-radius: 2 * $base-border-radius;
    font-size: 120%;
    text-align: center;
    transform-origin: 0 0;
    transform: scale(1) translate(-50%, -50%);
    animation: modal-body-on calc($base-animation-time / 1.5);
    transition-property: opacity, transform;
    transition-duration: calc($base-animation-time / 3);

    .hide & {
      opacity: 0;
      transform: scale(0.8) translate(-50%, -50%);
    }

    .large & {
      width: $modal-max-width;
    }

    & .columns {
      display: flex;
      flex-direction: row;
      margin-left: 10px;
      margin-right: 10px;
      & > div {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }

  &-title {
    margin: 30px;
    white-space: pre-line;
    * {
      user-select: text;
    }

    .success {
      color: $color-success;
    }
    .neutral {
      color: $color-neutral;
    }
    .danger {
      color: $color-danger;
    }
  }

  &-content {
    padding: 8px 20px;
    text-align: left;
    font-size: 16px;
  }

  &-header {
    font-size: 120%;
    margin-bottom: 5px;
  }

  &-scroll-area {
    width: 100%;
    max-height: 400px;
    overflow-y: auto;
    border-top: 1px solid $base-border-color;
    * {
      user-select: text;
    }
    &.errors {
      background-color: lighten($color-danger, 37);
    }

    .item {
      font-size: 80%;
      text-align: left;
      display: flex;
      flex-flow: row nowrap;
      padding: 8px 20px;
      border-bottom: 1px solid $base-border-color;
    }

    .item-id {
      display: inline-block;
      vertical-align: top;
      font-weight: bold;
      margin-right: 10px;
      white-space: nowrap;
    }

    .item-msg {
      display: inline-block;
      vertical-align: top;
      color: $color-text-gray;
    }
  }

  .loader {
    min-height: 200px;
  }

  .input {
    width: 60%;
    font-size: 100%;
    margin: 0 auto 30px;
  }

  .checkbox {
    margin: 0;
  }

  .dropdown {
    margin: 30px;
  }

  &-btn {
    display: block;
    width: 100%;
    color: $color-accent;
    line-height: 62px;
    border-top: 1px solid $base-border-color;
    cursor: pointer;
    background-color: rgba($color-accent, 0);
    &::first-letter {
      text-transform: uppercase;
    }
    transition: background-color $base-animation-time, color $base-animation-time;
    &:hover {
      background-color: rgba($color-accent, 0.04);
    }
    &:active {
      background-color: rgba($color-accent, 0.1);
    }
    &:disabled {
      color: desaturate($color-accent, 50%);
    }
    &.success {
      color: $color-success;
    }
    &.success:hover {
      background-color: rgba($color-success, 0.04);
    }
    &.success:active {
      background-color: rgba($color-success, 0.1);
    }
    &.success:disabled {
      color: lighten(desaturate($color-success, 25%), 25%);
    }
    &.danger {
      color: $color-danger;
    }
    &.danger:hover {
      background-color: rgba($color-danger, 0.04);
    }
    &.danger:active {
      background-color: rgba($color-danger, 0.1);
    }
    &.danger:disabled {
      color: desaturate($color-danger, 50%);
    }
    &-cancel {
      font-weight: bold;
    }

    &:disabled,
    &:disabled:hover {
      cursor: default;
      background-color: lighten($color-dark, 80%);
    }

    &.disabled {
      pointer-events: none;
      cursor: default;
    }
  }
}

@keyframes modal-fade-on {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes modal-body-on {
  0% {
    opacity: 0;
    transform: (scale(1.15) translate(-50%, -50%));
  }

  100% {
    opacity: 1;
    transform: (scale(1) translate(-50%, -50%));
  }
}
