@import 'bourbon', '../../../commons/styles/variables';

.segmented-control {
  width: 100%;
  margin: 10px 0;

  &-label {
    display: block;
    width: 100%;
    font-size: 85%;
    font-weight: bold;
  }

  &-btn-group {
    display: flex;
    flex-flow: row wrap;
  }

  &-btn {
    flex: 1;
    height: $base-button-height;
    margin: 10px 0;
    color: $color-accent;
    background-color: rgba($color-accent, 0.05);
    border: 1px solid $color-accent;
    border-right-width: 0;
    border-radius: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    &:hover {
      background-color: rgba($color-accent, 0.1);
    }
    &:active {
      background-color: rgba($color-accent, 0.15);
    }
    &:first-child {
      border-radius: $base-border-radius 0 0 $base-border-radius;
    }

    &:last-child {
      border-radius: 0 $base-border-radius $base-border-radius 0;
      border-right-width: 1px;
    }

    .disabled & {
      pointer-events: none;
      color: $color-text-gray;
      border-color: $base-border-color;
      background-color: #fff;
    }
  }

  &-btn.on {
    color: #fff;
    background-color: $color-accent;

    .disabled & {
      color: #fff;
      background-color: $color-text-gray;
    }
  }
}
