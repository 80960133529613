@import 'bourbon', '../../../commons/styles/variables';

.sent-messages-filters {
  .checkboxes .label {
    display: flex;
    justify-content: space-between;

    .statuses-shortcuts {
      font-size: 0.9em;
      color: $color-text-gray;

      span {
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
