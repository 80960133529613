@import 'bourbon', '../../../commons/styles/variables';

.table-owners {
  font-size: 90%;

  .cell-name {
    flex: 3;
  }

  .cell-vkm {
    flex: 1;
  }

  .cell-emails {
    flex: 3;
  }

  .table-body-row {
    .cell-name {
      font-size: 120%;
      font-weight: bold;
      margin-bottom: 5px;
    }
  }

  .table-header {
    a {
      // All columns are sortable
      cursor: pointer;
    }
  }

  @media (max-width: $device-width-tablet - 1px) {
    .table-body-row {
      flex-wrap: wrap;
    }

    .cell-emails {
      flex: 0 100%;
    }
  }
}
