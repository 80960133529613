@import 'bourbon', '../../../commons/styles/variables', '../../../commons/styles/icons';

.switch2 {
  position: relative;
  margin: 0 15px;

  &.disabled {
    pointer-events: none;
  }

  &-label {
    display: inline-block;
    height: 40px;
    margin: 0;
    line-height: 40px;
    padding-right: 12px;
    text-align: right;
    color: $color-accent;
    font-size: 85%;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;

    .on & {
      color: $color-success;
    }
  }

  &-root {
    position: relative;
    display: inline-block;
    opacity: 1;
    border-radius: 20px;
    transition: opacity 0.25s;
    touch-action: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    user-select: none;
    vertical-align: middle;

    .disabled & {
      opacity: 0.5;
    }
  }

  &-bg {
    height: 40px;
    width: 68px;
    margin: 0;
    position: relative;
    display: block;
    background-color: rgba($color-dark, 0.05);
    border-radius: 20px;
    transition: background 0.25s;

    .switch2:hover & {
      background-color: rgba($color-accent, 0.1);
    }

    .on & {
      background-color: $color-success;
    }
    .on:hover & {
      background-color: darken($color-success, 5);
    }
  }

  &-button {
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;

    .disabled & {
      cursor: default;
    }
  }

  &-handle {
    height: 34px;
    width: 34px;
    background-color: #fff;
    background-position: 50% 47%;
    background-repeat: no-repeat;
    background-size: 17px 17px;
    cursor: pointer;
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    top: 3px;
    outline: 0;
    border: 0;
    transition: background-color 0.25s, transform 0.25s, box-shadow 0.15s;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.11), 0 4px 8px 0 rgba(0, 0, 0, 0.11);

    &.outline {
      box-shadow: 0 0 2px 3px #3bf;
    }
    .disabled & {
      cursor: default;
    }
    .lock & {
      background-image: icon(unlocked, $color-gray);
    }
    .on.lock & {
      background-image: icon(locked, $color-gray);
    }
  }
}
