@import 'bourbon', '../../../../commons/styles/variables', '../../../../commons/styles/z-index',
  '../../../../commons/styles/icons';

.vehicle {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: $vehicles-height;
  padding: 10px;
  margin: 3px 0;
  background-color: #fff;
  border-radius: $base-border-radius;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.8);
  background-position: 48px 26px;
  background-size: 40px 40px;
  background-repeat: no-repeat;
  cursor: pointer;

  &.engine {
    background-image: icon(engine, $color-gray);

    .vehicle-index {
      font-weight: normal;
    }

    .rat {
      display: none;
    }
  }

  &.wagon {
    background-image: icon(wagon-empty, $color-gray);

    .vehicle-index {
      font-size: 175%;
      letter-spacing: -1px;
      font-weight: 300;
    }

    &.filled {
      background-image: icon(wagon-filled, $color-gray);
    }
  }

  &.active {
    box-shadow: 0 0 0 2px $color-accent, inset 0 0 0 3px $color-accent;

    .vehicle-index {
      color: $color-accent;
    }
  }

  &.ate::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: icon(arrow-long-up-down, $color-gray) 30px 30px no-repeat;
    background-size: 28px 28px;
  }

  &.ate.validated.wagon::before,
  &.ate.validated.engine::before {
    background-image: icon(arrow-long-up-down, rgba($color-success, 0.99));
  }

  &.drag {
    box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.6);
    @include z-index(drag);
    transition: none;
  }

  &.validated {
    background-color: $color-state-validated;

    &.engine {
      background-image: icon(engine, rgba($color-success, 0.99));
    }

    &.wagon {
      background-image: icon(wagon-empty, rgba($color-success, 0.99));
    }

    &.wagon.filled {
      background-image: icon(wagon-filled, rgba($color-success, 0.99));
    }
  }

  &.not-validated {
    background-color: $color-state-not-validated;
  }

  &-index {
    position: absolute;
    top: 3px;
    left: 6px;
  }

  &-serial {
    width: 138px;
    bottom: 15px;
    left: 0;
    position: absolute;
    display: block;
    text-align: center;
    font-size: 80%;
    font-weight: 900;
    color: $color-text-gray;
    white-space: nowrap;
  }

  &-hazmat {
    position: absolute;
    top: 29px;
    left: 52px;
    width: 32px;
    height: 25px;
    background-size: 32px 25px;
    background: url('../../../assets/images/plate.svg') 0 0 no-repeat;
    font-size: 50%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-bottom: 1px;

    > div {
      height: 10px;
    }
  }

  &-props {
    width: 70%;
    padding: 0;
    margin: 0 0 0 100px;
    position: relative;
    display: inline-block;
    vertical-align: top;
    list-style: none;

    li {
      margin-bottom: 5px;
    }

    &-value {
      display: inline-block;
      vertical-align: top;
      width: 30%;
      text-align: right;
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden;
    }

    &-label {
      display: inline-block;
      vertical-align: top;
      width: 67%;
      margin-left: 5px;
      color: $color-text-gray;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .btn.remove {
    position: absolute;
    top: 32px;
    right: 7px;
  }

  .vehicle-pictograms {
    position: absolute;
    top: 7px;
    right: 7px;
    display: flex;
    gap: 8px;
    flex-direction: row-reverse;
  }

  .rat,
  .charge-d,
  .rolling-goods {
    padding: 0 6px;
    height: 18px;
    width: 34px;
    line-height: 18px;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 60%;
    font-weight: 900;
    border-radius: calc($base-border-radius / 2);
    background-color: rgba($color-dark, 0.1);
  }

  .rat {
    &.on {
      background-color: $color-success;
    }
  }

  .charge-d {
    &.on {
      background-color: $color-danger;
    }
  }

  .rolling-goods {
    &.on {
      background-color: $color-accent;
    }
  }

  &.not-validated .rat,
  &.not-validated .charge-d,
  &.not-validated .rolling-goods {
    color: $color-state-not-validated;
  }

  &.validated .rat,
  &.validated .charge-d,
  &.validated .rolling-goods {
    color: $color-state-validated;
  }
}

.vehicles-list-container {
  .vehicles-validate {
    position: relative;

    .btn {
      position: absolute;
      width: 150px;
      height: 50px;
      bottom: 30px;
      left: calc(50% - 75px);
      box-shadow: rgba(0, 0, 0, 0.2) 0 3px 5px -1px, rgba(0, 0, 0, 0.14) 0px 6px 10px 0px,
        rgba(0, 0, 0, 0.12) 0px 1px 18px 0px;
      border-radius: 24px;
      text-align: center;

      &.disabled {
        background-color: rgba($color-button-disabled, 0.9);
      }
    }
  }

  &.collapsed .vehicle {
    background-position: 50% 20px;
    background-size: 30px 30px;
    padding-left: 6px;
    padding-right: 6px;

    .btn.remove {
      display: none;
    }

    &-hazmat {
      top: 23px;
      left: 50%;
      margin-left: -11px;
      width: 22px;
      height: 17px;
      background-size: 22px 17px;
      font-size: 35%;
    }

    &.ate::before {
      margin-left: -20px;
      background-position: 50% 20px;
    }

    &-serial {
      width: 100%;
      bottom: 30px;
    }

    &-props {
      width: 100%;
      margin: 65px 0 0;
      display: flex;
      justify-content: space-between;
      align-items: center;

      li {
        font-size: 80%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &-value {
        width: 100%;
        text-align: center;
      }

      &-label {
        display: none;
      }
    }

    .vehicle-pictograms {
      flex-direction: column;
      gap: 4px;
    }

    @media (max-width: $device-width-tablet - 1px) {
      margin: 0 3px;
      height: $vehicles-height-mobile;
      width: $vehicles-width-mobile;
      min-width: $vehicles-width-mobile;
      background-position-y: 8px;
      background-position-x: 36px;

      .composition.pre-advice & {
        height: $vehicles-height-mobile-preadvice;

        &-serial {
          bottom: 10px;
        }
      }

      &-hazmat {
        top: 11px;
        left: 51px;
      }

      &-serial {
        font-size: 65%;
      }

      &-props {
        display: none;
      }

      .vehicle-pictograms {
        bottom: 5px;
        top: unset;
        left: 5px;
        right: 5px;
        flex-direction: row;
        justify-content: space-around;
      }
    }
  }
}
