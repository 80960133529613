@import 'bourbon', '../../../commons/styles/variables', '../../../commons/styles/z-index',
  '../../../commons/styles/icons';

.toast {
  position: fixed;
  margin: 0 20px;
  min-width: $toast-min-width;
  max-width: $toast-max-width;
  bottom: 30px;
  right: 0;
  opacity: 1;
  cursor: pointer;
  padding: 20px 60px;
  color: rgba(#fff, 0.9);
  border-radius: $base-border-radius;
  background-color: $color-accent;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
  background-position: 20px 50%;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  animation: toast-on $base-animation-time backwards;
  animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  transition-property: opacity, transform;
  transition-duration: calc($base-animation-time / 3);
  transform: translate(0, 0);
  user-select: text;
  @include z-index(toast);

  @media (max-width: $device-width-tablet - 1px) {
    bottom: 130px;
  }

  &.online {
    background: $color-success icon(online, rgba(255, 255, 255, 0.99)) 20px 50% no-repeat;
  }
  &.offline {
    background: $color-accent icon(offline, rgba(255, 255, 255, 0.99)) 20px 50% no-repeat;
  }
  &.success {
    background: $color-success icon(checkmark, rgba(255, 255, 255, 0.99)) 20px 50% no-repeat;
  }
  &.error {
    background: $color-danger icon(close, rgba(255, 255, 255, 0.99)) 20px 50% no-repeat;
  }
  &.info {
    background: $color-accent icon(engine, rgba(255, 255, 255, 0.99)) 20px 50% no-repeat;
  }
  &.warning {
    background: $color-neutral icon(close, rgba(255, 255, 255, 0.99)) 20px 50% no-repeat;
  }

  &.off {
    transform: scale(0.8);
    opacity: 0;
  }

  &.left {
    left: 0;
    right: auto;
  }

  &.higher {
    @media (min-width: $device-width-tablet) {
      bottom: 90px;
    }
  }
}

@keyframes toast-on {
  0% {
    transform: translate(0, 200px);
    opacity: 0;
  }

  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}
