@import '../../../commons/styles/variables';

.composition-form div.traceability-summary {
  .copied-message {
    margin-bottom: 10px;
  }

  .buttons {
    display: flex;
    height: 25px;
    opacity: 1;
    transition-property: opacity;
    transition-duration: calc($base-animation-time);
    .btn {
      display: block;
      margin-left: 8px;
    }
  }

  &.collapsed .buttons {
    opacity: 0;
    pointer-events: none;
  }

  .statuses {
    position: unset;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    row-gap: 4px;
    margin-top: 20px;
    pointer-events: none;

    .status {
      flex-basis: 50%;

      @media (max-width: $device-width-desktop - 1px) {
        flex-basis: 100%;
      }
    }
  }
}

.overlay-traceability {
  .overlay-body {
    background-color: $color-background-gray;
  }

  .overlay-title {
    padding: 0 10px;
    margin-bottom: 10px;
  }

  .form-section {
    margin-bottom: 10px;
    position: relative;

    > .title {
      font-size: 125%;
    }

    .list {
      margin: 0;
    }

    .btn.plus {
      position: absolute;
      right: 15px;
      top: 10px;
    }

    .empty {
      margin-top: 4px;
    }

    form {
      display: flex;
      flex-direction: column;
    }

    .brake-test-form,
    .first-last-form,
    .buttons {
      padding: 10px 15px 15px;
      margin: 0 -15px -15px;
      background-color: white;
      border-radius: 0 0 6px 6px;

      & > .btn {
        margin: 0;
      }
    }

    .subsection {
      padding-top: 10px;
      position: relative;

      &:not(:last-child) {
        border-bottom: 1px solid $base-border-color;
        padding-bottom: 10px;
      }

      > .subsection-title {
        font-weight: bold;
        margin-bottom: 4px;
      }

      > .btn.plus {
        right: 0;
        top: 5px;
      }

      .buttons {
        padding: 10px 0;
        margin: 0 0 -10px;
        background: transparent;
      }
    }

    .subsection-content {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      & + .subsection-content,
      & + .buttons,
      & + form {
        border-top: 1px solid $base-border-color;
      }
    }
  }
}

.form-section {
  &.partial {
    background-color: $color-state-not-validated;
    border-color: darken($color-state-not-validated, 15);
  }

  &.signed {
    background-color: $color-state-validated;
    border-color: darken($color-state-validated, 15);
  }
}
