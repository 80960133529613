@import 'bourbon', '../../../commons/styles/variables';

.list {
  display: block;
  list-style: none;
  width: 100%;
  margin: 0 0 15px;
  padding: 0;
  * {
    user-select: text;
  }

  li {
    margin: 8px 0;
    padding: 0;
  }

  &-key {
    vertical-align: top;
    display: inline-block;
    width: 40%;
    margin-right: 5px;
    font-weight: normal;
    color: $color-text-gray;
  }

  &-value {
    font-weight: bold;
    vertical-align: top;
    display: inline-block;

    &-item {
      display: block;
      margin-bottom: 5px;
    }
  }
}
