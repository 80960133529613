@import '../commons/styles/variables', '../commons/styles/icons';

.statuses {
  position: absolute;
  right: 20px;
  bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .status {
    padding-left: 20px;
    font-size: 90%;
    color: $color-gray;
    background: icon(close, rgba($color-gray, 0.35)) 0 50% no-repeat;
    background-size: 12px 12px;
    &.on {
      color: darken($color-success, 10);
      font-weight: bold;
      background-image: icon(checkmark, rgba($color-success, 0.99));
      background-position-x: -3px;
      background-size: 20px 20px;
    }
  }

  @media (max-width: $device-width-tablet - 1px) {
    position: static;
    width: 100%;
    flex-direction: row;
    gap: 10px;
  }
}
