.btn-review-wagon-templates {
  &.btn-link {
    min-width: 160px;
    flex-shrink: 0;

    &:disabled {
      opacity: 0.5;
    }
  }

  &.btn-round {
    margin-right: 8px;
  }
}
