@import 'bourbon', '../../../commons/styles/variables', '../../../commons/styles/icons';

.input {
  position: relative;

  input,
  textarea {
    width: 100%;
    display: block;
    position: relative;
    height: $base-button-height;
    margin: 10px auto;
    padding: 10px;
    color: $color-text-default;
    border-radius: $base-border-radius;
    border: 1px solid $base-border-color;
    outline: none;
    background-color: #fff;
    font-weight: normal;
    appearance: none;
    user-select: text;
    ::placeholder {
      color: $color-text-gray;
    }
    &::-moz-focus-inner {
      border: 0;
    }
    &:required {
      box-shadow: none;
    }
    &::-ms-clear {
      display: none;
    }
    &::-ms-reveal {
      display: none;
    }
    &:invalid {
      box-shadow: none;
    }
    &.right {
      text-align: right;
    }

    &:disabled {
      -webkit-text-fill-color: $color-text-default;
      color: $color-text-default;
      background-color: rgba($color-dark, 0.05);
      opacity: 1;
    }

    &:read-only {
      -webkit-text-fill-color: $color-text-default;
      color: $color-text-default;
      background-color: rgba($color-dark, 0.05);
      opacity: 1;
    }

    &:focus {
      border-color: $color-accent;
      box-shadow: inset 0 0 0 1px $color-accent;
    }
    &:focus.icon.route-from {
      background-image: icon(route-from, rgba($color-accent, 0.99));
    }
    &:focus.icon.route-to {
      background-image: icon(route-to, rgba($color-accent, 0.99));
    }
    &:focus.icon.star {
      background-image: icon(star, rgba($color-accent, 0.99));
    }

    &.icon {
      padding: 0 10px 0 40px;
      background-size: 20px 20px;
      background-position: 10px 50%;
      background-repeat: no-repeat;
    }
    &.icon.route-from {
      background-image: icon(route-from, $color-gray);
    }
    &.icon.route-to {
      background-image: icon(route-to, $color-gray);
    }
    &.icon.star {
      background-image: icon(star, $color-gray);
    }
  }

  textarea {
    resize: none;
    height: 100px;
  }

  input[type='number'] {
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  label {
    font-size: 85%;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    user-select: text;
  }
  &-label {
    margin: 10px 0;
  }

  &-unit .unit {
    position: absolute;
    top: 17px;
    right: 0;
    width: calc($base-button-height / 1.2);
    height: $base-button-height - 16px;
    line-height: $base-button-height - 16px;
    border-left: 1px solid $base-border-color;
    text-align: center;
    font-weight: bold;
    font-size: 80%;
    color: $color-text-gray;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &-label .unit {
    bottom: 33px;
    top: 34px;
  }
  &-unit input {
    padding-right: $base-button-height;
  }

  &-error input,
  &-error textarea {
    border-color: $color-danger;
  }

  &-autofill input {
    animation: input-autofill 5 * $base-animation-time;
  }

  &-valid input,
  &-valid textarea {
    animation: input-valid 5 * $base-animation-time;
  }

  &.dropdown-icon::after {
    content: '';
    width: $base-button-height;
    height: $base-button-height;
    position: absolute;
    top: 0;
    right: 0;
    background-image: icon(arrow-down, $color-gray);
    background-position: 60% 50%;
    background-repeat: no-repeat;
    pointer-events: none;
  }
}

.error-message {
  font-size: 85%;
  color: $color-danger;
  white-space: normal;
}

/* Saved field animation */
@keyframes input-valid {
  20%,
  80% {
    border-color: $color-success;
    box-shadow: inset 0 0 0 1px $color-success;
  }

  100% {
    border-color: $base-border-color;
    box-shadow: none;
  }
}

/* Autofilled field animation */
@keyframes input-autofill {
  20%,
  80% {
    border-color: $color-accent;
  }

  100% {
    border-color: $base-border-color;
    box-shadow: none;
  }
}
