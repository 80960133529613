@import 'bourbon', '../../../commons/styles/variables', '../../../commons/styles/z-index',
  '../../../commons/styles/icons';

.dropdown {
  cursor: pointer;
  position: relative;

  &-button {
    width: 100%;
    display: block;
    position: relative;
    height: $base-button-height;
    margin: 10px auto;
    padding: 10px 40px 10px 10px;
    text-align: left;
    color: $color-text-default;
    border-radius: $base-border-radius;
    border: 1px solid $base-border-color;
    outline: none;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #fff;
    appearance: none;

    &::after {
      content: '';
      width: $base-button-height;
      height: $base-button-height;
      position: absolute;
      top: 0;
      right: 0;
      background-image: icon(arrow-down, $color-gray);
      background-position: 60% 50%;
      background-repeat: no-repeat;
    }
    .non-selection & {
      color: $color-text-gray;
    }

    .on & {
      border-color: $color-accent;
      box-shadow: inset 0 0 0 1px $color-accent;
    }

    &:disabled {
      -webkit-text-fill-color: $color-text-default;
      color: $color-text-default;
      background-color: rgba($color-dark, 0.05);
      opacity: 1;
    }

    &.icon {
      padding: 0 10px 0 40px;
      background-size: 20px 20px;
      background-position: 10px 50%;
      background-repeat: no-repeat;
      &.labels {
        background-image: icon(labels, $color-gray);
        background-size: 24px 24px;
        background-position: 5px 50%;
      }
    }
  }

  &-label {
    margin: 10px 0;
  }

  &.dropdown-error {
    .dropdown-button {
      border: 1px solid $color-danger;
    }
  }
}

label {
  font-size: 85%;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 10px;
  display: block;
  user-select: text;
}

.dropdown-container {
  position: absolute;
  width: 100%;
  max-height: 220px;
  background-color: #fff;
  border-radius: $base-border-radius;
  box-shadow: 0 20px 80px rgba(0, 0, 0, 0.2);
  @include z-index(dropdown);
  animation: dropdown-on calc($base-animation-time / 2);
}

.dropdown-body {
  max-height: 220px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.dropdown-option {
  width: 100%;
  padding: 0 20px;
  height: $base-button-height;
  line-height: $base-button-height;
  text-align: left;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: 1px solid $base-border-color;
  &:last-child {
    border: 0;
  }
  &:hover {
    background-color: rgba($color-accent, 0.1);
  }
  &:active {
    background-color: rgba($color-accent, 0.15);
  }
}

@keyframes dropdown-on {
  0% {
    opacity: 0;
    transform: translate(0, 20px);
  }

  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}
