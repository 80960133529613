.profile-details {
  &.overlay-body {
    display: flex;
    flex-direction: column;

    > form {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      height: initial;

      > * {
        flex-shrink: 0;
      }
    }
  }
}
