@import 'bourbon', '../../../commons/styles/variables';

.hazmats {
  width: 100%;
}

.hazmat {
  position: relative;
  padding: 0 20px;
  margin: 10px 0 0;
  border-radius: $base-border-radius;
  background-color: rgba($color-dark, 0.05);
  animation: hazmat-form-on calc($base-animation-time / 2);
  transition: opacity calc($base-animation-time / 2), transform calc($base-animation-time / 2);
  &:last-child {
    margin-bottom: 40px;
  }

  &-header {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 15px 0;

    .title {
      font-size: 120%;
      margin: 0 auto 0 0;
    }
  }

  &.hide {
    opacity: 0;
    transform: (scale(0.5));
  }

  .fields .col {
    flex: 30% 2;
  }

  &-description {
    flex: 60% 4;
    line-height: 140%;
    margin-bottom: 20px;
    user-select: text;

    &-title {
      width: 100%;
      margin-bottom: 5px;
      font-size: 85%;
      font-weight: bold;
      user-select: text;
    }
  }

  &-images {
    flex: 45% 3;
    img {
      width: 120px;
      height: 120px;
      margin-right: 10px;
      display: inline-block;
    }
  }

  .fields {
    .hazmat-packing-group,
    .hazmat-description {
      .dropdown {
        width: 100%;
      }
    }
  }
}

@keyframes hazmat-form-on {
  0% {
    opacity: 0;
    transform: translate(0, -20px);
  }

  100% {
    opacity: 100%;
    transform: translate(0, 0);
  }
}

.hazmat-plate {
  display: block;
  max-width: 130px;
  margin: 0 auto 30px;

  input {
    height: 40px;
    width: 100%;
    outline: 0;
    border-radius: 0;
    border: 4px solid #000;
    background-color: #fe9900;
    text-align: center;
    font-size: 135%;
    font-weight: 900;
    appearance: none;
    user-select: text;
    &::-moz-focus-inner {
      border: 0;
    }
    &:required {
      box-shadow: none;
    }
    &::-ms-clear {
      display: none;
    }
    &::-ms-reveal {
      display: none;
    }
    &:invalid {
      box-shadow: none;
    }

    &:first-child {
      border-radius: 4px 4px 0 0;
    }

    &:nth-child(2) {
      border-top: 0;
      border-radius: 0 0 4px 4px;
      margin-bottom: 5px;
    }

    &:disabled {
      color: $color-text-default;
    }

    ::placeholder {
      font-family: 'Lato', sans-serif;
      font-weight: normal;
      color: $color-text-default;
    }
  }

  .error-message {
    font-size: 85%;
    color: $color-danger;
    white-space: normal;
  }
}

.grouping {
  .error-message {
    font-size: 85%;
    color: $color-danger;
    white-space: normal;
  }
}
