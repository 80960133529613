@import 'bourbon', '../../../commons/styles/variables', '../../../commons/styles/icons',
  '../../../commons/styles/z-index';

.damage-reports-filters {
  #filter-status-empty {
    background-image: url('../../assets/images/icon-damage-report-state-empty-or-in-progress.svg');
  }

  #filter-status-nv {
    background-image: url('../../assets/images/icon-damage-report-state-submitted.svg');
  }

  #filter-status-validated {
    background-image: url('../../assets/images/icon-damage-report-state-validated.svg');
  }

  ~ .btn.plus {
    margin-left: 8px;
  }

  ~ .btn-success:not(.btn-round) {
    min-width: unset;
  }

  .switch2.only-mine {
    .switch2-label {
      color: $color-text-gray;
    }

    &.on {
      .switch2-label {
        color: $color-accent;
      }

      .switch2-bg {
        background-color: $color-accent;
      }
    }
  }
}
