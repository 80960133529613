@import 'bourbon', '../../../commons/styles/variables', '../../../commons/styles/icons';

.table-vehicles-library {
  .table-body {
    overflow-x: hidden;
  }

  .table-body-row {
    font-size: 90%;
    padding: 15px;
    background-position: 20px 30%;
    background-repeat: no-repeat;
    white-space: nowrap;

    &.row-engine {
      cursor: default;
      background-image: icon('engine', $color-gray);
      color: $color-text-gray;
    }

    &.row-wagon {
      background-image: icon('wagon-empty', rgba($color-black, 0.99));
    }

    .cell-registration {
      padding-left: 30px;
      font-weight: bold;
      text-transform: uppercase;
    }

    &.loader {
      position: relative;
    }
  }

  .cell-registration {
    width: 150px;
    margin-right: 3%;
    white-space: nowrap;
  }

  .cell-owner {
    min-width: 170px;
    flex: 1;
    margin-right: 3%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .sub-cell-number-serie {
    width: 100%;
    font-weight: normal;
    font-size: 0.8em;
    margin-right: 3%;
    white-space: nowrap;
    padding-left: 0.4%;
    padding-top: 0.4%;
  }

  .cell-params {
    min-width: 240px;

    .value {
      width: 75px;
      white-space: nowrap;
      display: inline-block;
      text-overflow: ellipsis;
      overflow: hidden;

      &.value-length {
        width: 65px;
      }

      &.value-tare-engine {
        width: 40px;
      }

      &.value-mode {
        width: 70px;
      }
    }
  }

  .cell-company {
    flex: 1;
  }

  .cell-wagon-template-status {
    color: $color-neutral;
  }
}
