@import '../../../commons/styles/variables';

.composition-form div.braking-bulletin-list > .content {
  > ul {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;

    > li.braking-bulletin-item {
      padding: 0 8px;
      margin: 10px 0 0;
      border-radius: $base-border-radius;
      background-color: rgba($color-dark, 0.05);
      display: flex;
      align-items: center;
      cursor: pointer;

      &.invalidated {
        .version,
        .details {
          opacity: 0.4;
        }
      }

      &.signed {
        background-color: $color-state-validated;
      }

      &.draft {
        background-color: $color-state-not-validated;
      }

      .version {
        font-weight: bold;
      }

      .details {
        flex: 1;
        margin-left: 8px;

        .list {
          margin: 0;

          &:not(:last-child) {
            border-bottom: 1px solid $base-border-color;
          }
        }
      }

      .btn {
        margin: 8px 0 8px 8px;
      }
    }
  }

  > .buttons {
    justify-content: space-between;

    > .btn-round {
      margin-right: 0;
    }
  }
}

.overlay-braking-bulletin {
  .overlay-body {
    background-color: $color-background-gray;

    > form {
      height: calc(100% - 40px);

      > .btn-group {
        gap: 10px;

        > .btn {
          margin: 0;
          min-width: auto;

          &.btn-link {
            padding: 0 5px;
          }
        }
      }
    }
  }

  .overlay-title {
    margin-bottom: 10px;
  }

  .braking-bulletin-form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;

    > .input {
      width: 100%;
      margin: 0;

      &:not(.input-error) {
        input {
          margin-bottom: 0;
        }
      }
    }

    > .indice-composition {
      width: 100%;

      button {
        margin: 0;
      }
    }

    .braking-bulletin-composition,
    .braking-bulletin-observations,
    .braking-bulletin-messages {
      width: 100%;
    }

    .braking-bulletin-composition {
      .input {
        input {
          margin: 0;
        }

        .unit {
          top: 7px;
        }
      }

      .composition-flags {
        display: flex;
        flex-wrap: wrap;
        column-gap: 15px;
        justify-content: space-between;

        .checkbox {
          margin: 0;
          @media (max-width: $device-width-tablet - 1px) {
            flex-basis: 40%;
          }
        }
      }

      @media (max-width: $device-width-tablet - 1px) {
        .composition-stats {
          margin-left: -12px;
          margin-right: -8px;

          th {
            font-size: 90%;
          }

          .input input {
            padding: 6px;
          }

          .input-unit {
            input {
              padding-right: 23px;
            }

            .unit {
              width: 20px;
            }
          }
        }
      }
    }

    .braking-bulletin-observations {
      .add {
        margin-top: 10px;
      }
    }

    .observation {
      position: relative;
      padding: 10px;
      margin: 10px 0 0;
      border-radius: $base-border-radius;
      background-color: rgba($color-dark, 0.05);

      .header {
        display: flex;
        align-items: center;

        .title {
          font-size: 120%;
          flex: 1;
        }
      }

      .input {
        display: inline-block;
        width: 165px;
        vertical-align: middle;

        &.full {
          width: 100%;
        }

        input {
          margin: 4px 0;
        }

        .unit {
          top: 11px;
        }
      }

      .big-field-line {
        display: flex;
        align-items: center;
        gap: 4px;

        .input {
          width: auto;
          flex: 1;
        }
      }
    }

    .braking-bulletin-required,
    .braking-bulletin-effective {
      flex: 1;
      flex-basis: 40%;
      @media (max-width: $device-width-tablet - 1px) {
        flex-basis: 100%;
      }
    }

    .form-section {
      margin: 0;

      > .title {
        font-size: 125%;
      }
    }

    .checkbox {
      margin-right: 20px;
    }

    .braking-bulletin-messages {
      display: flex;
      flex-direction: column;
      gap: 15px;

      .message {
        padding: 8px;
        border: 1px solid $base-border-color;
        border-radius: $base-border-radius;
        background-color: rgba($color-dark, 0.05);

        &.info {
          background-color: lighten($color-accent, 38);
          border-color: lighten($color-accent, 25);
          color: darken($color-accent, 25);
        }

        &.warning {
          background-color: lighten($color-neutral, 37);
          border-color: lighten($color-neutral, 10);
          color: darken($color-neutral, 40);
        }

        &.error {
          background-color: lighten($color-danger, 37);
          border-color: lighten($color-danger, 25);
          color: darken($color-danger, 30);
        }
      }
    }
  }
}
