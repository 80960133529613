@import '../../../commons/styles/variables';

.composition-form div.mission-end-summary {
  .title {
    & + .btn {
      margin-top: 0;
    }
  }

  .mission-end-summary-items {
    padding-top: 10px;
  }

  .mission-end-summary-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &:not(:last-child) {
      border-bottom: 1px solid $base-border-color;
    }

    & .list {
      margin: 0;
    }
  }
}
