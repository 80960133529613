@import '../../../../styles/variables', '../../../../styles/icons';

.btn.btn-open-damage-report {
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;
  color: $color-accent;
  background-image: icon(damage-reports, rgba($sidebar-color-active, 0.99), 'transparent', 0, '', 18);

  @media (max-width: 850px) {
    padding: 0;
    margin: 0;
    font-size: 0; // Hide button label
    width: $base-button-height;
    min-width: $base-button-height;
    height: $base-button-height;
    border-radius: calc($base-button-height / 2);
    background-size: calc($base-button-height / 2) calc($base-button-height / 2);
    background-color: rgba($color-dark, 0.05);
    background-repeat: no-repeat;
    background-position: 50% 50%;

    &:hover {
      background-color: rgba($sidebar-color-active, 0.05);
    }
  }
}
