@import 'bourbon', '../../commons/styles/variables', '../trainStatusStyles';

.train-details {
  padding: 20px;
  height: 100%;
  background-color: $color-background-gray;
  flex-flow: column nowrap;
  justify-content: space-between;
  position: relative;
  overflow-y: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;

  .title {
    margin-top: 0;
    margin-bottom: 8px;

    @media (max-width: $device-width-tablet - 1px) {
      padding-left: 40px;
    }
  }

  .contract {
    color: $color-text-gray;
  }

  .step {
    position: relative;
    overflow: hidden;
    margin-top: 16px;
    background-color: #fff;
    border-radius: $base-border-radius;
    border: 1px solid $base-border-color;
    cursor: pointer;

    &.validated {
      background-color: $color-state-validated;
      border-color: darken($color-state-validated, 15);
    }

    &.not-validated {
      background-color: $color-state-not-validated;
      border-color: darken($color-state-not-validated, 15);
    }

    &.copied {
      .statuses {
        bottom: 29px;
        background-color: #fff;
        box-shadow: 0 0 10px 4px #fff;
      }

      &.validated .statuses {
        background-color: $color-state-validated;
        box-shadow: 0 0 10px 4px $color-state-validated;
      }
      &.not-validated .statuses {
        background-color: $color-state-not-validated;
        box-shadow: 0 0 10px 4px $color-state-not-validated;
      }
    }

    &.active {
      border-width: 2px;
    }

    &.inactive {
      opacity: 70%;
    }

    & > a {
      display: block;
      padding: 10px;
    }

    .actions {
      float: right;
      display: flex;
      flex-direction: row;

      & > .btn {
        display: block;
        padding: 0;
        min-width: 44px;
        background-position-x: 50%;
      }
    }

    .header {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      margin-bottom: 8px;
    }

    .train-no {
      font-size: 80%;
      font-weight: 900;
      text-transform: uppercase;
      letter-spacing: 2px;
      color: #fff;
      background-color: $color-gray;
      padding: 3px 5px;
      border-radius: calc($base-border-radius / 2);
      text-align: center;
      width: 105px;
      margin-right: 35px;
    }

    .route {
      font-weight: bold;
    }

    .content {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .content-col {
        flex-basis: 300px;
        flex-grow: 1;
        flex-shrink: 0;
      }
    }

    .copied-composition {
      font-size: 75%;
      text-align: right;
      font-style: italic;
    }
  }
}
