/**
* Main colors
*/
$color-accent: rgb(40, 111, 255);
$color-success: rgb(53, 210, 105);
$color-neutral: rgb(255, 178, 51);
$color-danger: rgb(255, 53, 78);
$color-dark: rgb(31, 37, 55);
$color-black: darken($color-dark, 10);

/**
* Colors
*/
$color-gray: rgba($color-dark, 0.35);
$color-text-default: $color-black;
$color-text-gray: rgba($color-dark, 0.5);
$color-button-disabled: lighten($color-dark, 70);
$color-background-gray: rgb(247, 247, 247);
$color-state-empty: rgb(255, 255, 255);
$color-state-not-validated: lighten($color-neutral, 37);
$color-state-validated: lighten($color-success, 45);
$color-state-disabled: rgba($color-dark, 0.11);
$color-received-pre-advice: rgba(5, 205, 255, 0.92);
$color-state-received-pre-advice-background: lighten($color-received-pre-advice, 45);

/**
* Basic values
*/
$base-border-radius: 7px;
$base-animation-time: 0.325s;
$base-border-color: rgba($color-dark, 0.11);
$base-border-color-dark: rgba($color-dark, 0.21);
$base-button-height: 44px;
$base-content-min-width: 744px;

/**
* Responsive
*/
$device-width-tablet: 744px;
$device-width-desktop: 1024px;

/**
* Buttons
*/
$button-large-height: 1.37 * $base-button-height;

/**
* Login form
*/
$login-form-width: 300px;
$login-form-input-height: $base-button-height;
$login-form-submit-height: 54px;
$login-form-color-disabled: rgba(255, 255, 255, 0.1);
$login-form-color-placeholder: rgba(255, 255, 255, 0.6);
$login-form-color-default: rgba(255, 255, 255, 0.8);

/**
* Sidebar
*/
$sidebar-max-width: 250px;
$sidebar-min-width: 72px;
$sidebar-button-min-height: 42px;
$sidebar-button-max-height: 60px;
$sidebar-color-default: rgba(lighten($color-accent, 45), 0.8);
$sidebar-color-aux: rgba(lighten($color-accent, 30), 0.4);
$sidebar-color-active: $color-accent;
$sidebar-avatar-size: 44px;

/**
* Modal
*/
$modal-min-width: 320px;
$modal-max-width: 480px;

/**
* Overlay
*/
$overlay-min-width: 320px;
$overlay-max-width: 520px;

/**
* Toast
*/
$toast-min-width: 300px;
$toast-max-width: 600px;

/**
* Table
*/
$table-header-height: 28px;
$table-row-height: 52px;

/**
* Vehicles
*/
$vehicles-min-width: 204px;
$vehicles-height: 105px;
$vehicles-height-mobile: 90px;
$vehicles-width-mobile: 92px;
$vehicles-height-mobile-preadvice: 70px;
