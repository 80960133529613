@import
  'bourbon',
  './variables';

%offline {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba($color-gray, 0.2);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
