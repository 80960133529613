@import 'bourbon', '../../../commons/styles/variables', '../../../commons/styles/z-index',
  '../../../commons/styles/icons';

.sidebar {
  height: 100%;
  overflow: hidden;
  position: fixed;
  left: 0;
  color: $sidebar-color-aux;
  width: $sidebar-max-width;
  background-color: $color-dark;
  @include z-index(sidebar);
  transform: translate3d(0, 0, 0);
  transition: transform $base-animation-time;
  background-image: linear-gradient(37deg, $color-dark 0%, $color-accent 500%);

  &.mobile {
    @include z-index(sidebar-mobile);
  }

  &-backdrop {
    background-color: rgba($color-dark, 0.7);
    height: 100%;
    width: 100%;
    opacity: 1;
    position: fixed;
    transform: translate3d(0, 0, 0);
    animation: overlay-fade-on $base-animation-time;
    transition: opacity calc($base-animation-time / 3);
    @include z-index(sidebar-backdrop);
    &.hidden {
      opacity: 0;
      pointer-events: none;
    }
  }

  &.collapsed {
    transform: translate3d($sidebar-min-width - $sidebar-max-width, 0, 0);
    &.hidden {
      transform: translate3d(-$sidebar-max-width, 0, 0);
    }
    .sidebar-title {
      display: none;
    }
  }

  &-inner {
    height: 100%;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    transform: translate3d(0, 0, 0);
    transition: transform $base-animation-time;
    .collapsed & {
      transform: translate3d($sidebar-max-width - $sidebar-min-width, 0, 0);
    }
  }

  &-separator {
    height: 1px;
    margin: 15px 0;
    background-color: rgba(#000, 0.3);

    .collapsed:not(.mobile) &,
    &:last-child,
    + div > .sidebar-separator {
      display: none;
    }
  }

  &-btn-group {
    margin-top: 5px;
    margin-bottom: 20px;
    transition: transform $base-animation-time;
    transform: translate(0, 15px);
    .collapsed:not(.mobile) & {
      transform: translate3d(0, 0, 0);
    }
  }

  &-btn {
    position: relative;
    color: $sidebar-color-default;
    cursor: pointer;
    display: block;
    font-size: 120%;
    font-weight: 300;
    height: $sidebar-button-min-height;
    line-height: $sidebar-button-min-height;
    padding: 0 20px 0 0;
    text-align: left;
    text-decoration: none;
    width: $sidebar-max-width;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-position: 20px 50%;
    background-size: 20px 20px;
    background-repeat: no-repeat;
    &-trains {
      background-image: icon(engine, $sidebar-color-aux);
    }
    &-preadvice {
      background-image: icon(preadvice, $sidebar-color-aux);
    }
    &-damage-reports {
      background-image: icon(damage-reports, $sidebar-color-aux);
    }
    &-logout {
      background-image: icon(logout, $sidebar-color-aux);
    }
    &-expand {
      background-image: icon(expand, $sidebar-color-aux);
    }
    &-collapse {
      background-image: icon(collapse, $sidebar-color-aux);
    }
    &-users {
      background-image: icon(users, $sidebar-color-aux);
    }
    &-users-profiles {
      background-image: icon(users-profiles, $sidebar-color-aux);
    }
    &-vehicles-library {
      background-image: icon(vehicles-library, $sidebar-color-aux);
    }
    &-sent-messages {
      background-image: icon(sent-messages, $sidebar-color-aux);
    }
    &-mailing {
      background-image: icon(mailing, $sidebar-color-aux);
    }
    &-owners {
      background-image: icon(owners, $sidebar-color-aux);
    }
    &:hover {
      background-color: rgba(#000, 0.25);
    }
    &:active {
      background-color: rgba(#000, 0.3);
    }
    span {
      padding-left: 60px;
    }

    .collapsed:not(.mobile) & {
      padding: 0;
      height: $sidebar-button-max-height;
      background-position: 24px 50%;
      background-size: 24px 24px;
      span {
        display: none;
      }
    }
  }

  &-btn.on,
  &-btn.on:hover,
  &-btn.on:active {
    font-weight: normal;
    color: $sidebar-color-active;
    background-color: #fff;
    &.sidebar-btn-trains {
      background-image: icon(engine, rgba($sidebar-color-active, 0.99));
    }
    &.sidebar-btn-preadvice {
      background-image: icon(preadvice, rgba($sidebar-color-active, 0.99));
    }
    &.sidebar-btn-damage-reports {
      background-image: icon(damage-reports, rgba($sidebar-color-active, 0.99));
    }
    &.sidebar-btn-users {
      background-image: icon(users, rgba($sidebar-color-active, 0.99));
    }
    &.sidebar-btn-users-profiles {
      background-image: icon(users-profiles, rgba($sidebar-color-active, 0.99));
    }
    &.sidebar-btn-vehicles-library {
      background-image: icon(vehicles-library, rgba($sidebar-color-active, 0.99));
    }
    &.sidebar-btn-sent-messages {
      background-image: icon(sent-messages, rgba($sidebar-color-active, 0.99));
    }
    &.sidebar-btn-mailing {
      background-image: icon(mailing, rgba($sidebar-color-active, 0.99));
    }
    &.sidebar-btn-owners {
      background-image: icon(owners, rgba($sidebar-color-active, 0.99));
    }
  }

  &-btn-expand,
  &-btn-collapse {
    background-position: right 20px center;
    &:hover {
      background-color: transparent;
    }

    span {
      font-size: 80%;
      font-weight: bold;
      letter-spacing: 2px;
      padding-left: 20px;
      color: $sidebar-color-aux;
    }

    .collapsed:not(.mobile) & {
      padding: 0;
      height: $sidebar-button-max-height;
      background-position: 24px 50%;
      background-size: 24px 24px;
    }
  }

  &-footer {
    margin-bottom: 20px;
    white-space: nowrap;
    color: $sidebar-color-aux;
  }

  &-footer-user {
    display: inline-block;
    vertical-align: middle;
    width: 70%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-footer-username {
    color: $sidebar-color-default;
    font-size: 120%;
    font-weight: 300;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .collapsed & {
      display: none;
    }
  }

  &-footer-role {
    margin-top: 2px;
    color: $sidebar-color-aux;
    font-size: 75%;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    .collapsed & {
      display: none;
    }
  }

  &-footer-initials {
    margin: 0 15px 0 20px;
    display: inline-block;
    vertical-align: middle;
    width: $sidebar-avatar-size;
    height: $sidebar-avatar-size;
    line-height: $sidebar-avatar-size;
    border-radius: calc($sidebar-avatar-size / 2);
    color: $color-dark;
    background-color: $sidebar-color-aux;
    font-size: 120%;
    font-weight: bold;
    text-align: center;
    cursor: default;
    overflow: hidden;
    .collapsed & {
      margin: 0 calc(($sidebar-min-width - $sidebar-avatar-size) / 2);
    }
  }

  &-footer-meta {
    font-size: 75%;
    margin: 20px 20px 0;
    letter-spacing: 1px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .collapsed:not(.mobile) & {
      display: none;
    }
  }

  &-footer-time {
    display: inline-block;
    text-transform: uppercase;
    margin-left: 5px;
    .collapsed & {
      display: none;
    }
  }
}

.menu-button {
  position: fixed;
  @include z-index(sidebar-button);
  top: 14px;
  left: 8px;
}
