@import 'bourbon', '../../../../commons/styles/variables', '../../../../commons/styles/z-index';

.signature-modal {
  height: 100%;
  left: 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 500;
  @include z-index(modal);

  &-fade {
    background-color: rgba($color-dark, 0.7);
    height: 100%;
    width: 100%;
    opacity: 1;
    position: absolute;
    animation: modal-fade-on 2 * $base-animation-time;
    transition: opacity calc($base-animation-time / 3);
    .hide & {
      opacity: 0;
    }
  }

  &-body {
    background-color: #fff;
    color: $color-text-default;
    position: fixed;
    left: 50%;
    top: 50%;
    overflow: hidden;
    width: $modal-min-width;
    box-shadow: 0 20px 80px rgba(0, 0, 0, 0.4);
    border-radius: 2 * $base-border-radius;
    font-size: 120%;
    text-align: center;
    transform-origin: 0 0;
    transform: scale(1) translate(-50%, -50%);
    animation: modal-body-on calc($base-animation-time / 1.5);
    transition-property: opacity, transform;
    transition-duration: calc($base-animation-time / 3);

    .hide & {
      opacity: 0;
      transform: scale(0.8) translate(-50%, -50%);
    }
  }

  &-title {
    margin: 30px;
    user-select: text;
  }

  .input {
    width: 60%;
    font-size: 120%;
    margin: 0 auto 30px;
  }

  &-btn {
    display: block;
    width: 100%;
    color: $color-accent;
    line-height: 62px;
    border-top: 1px solid rgba(0, 0, 0, 0.14);
    cursor: pointer;
    background-color: rgba($color-accent, 0);
    &:hover {
      background-color: rgba($color-accent, 0.04);
    }
    &:active {
      background-color: rgba($color-accent, 0.1);
    }
    &.success {
      color: $color-success;
    }
    &.success:hover {
      background-color: rgba($color-success, 0.04);
    }
    &.success:active {
      background-color: rgba($color-success, 0.1);
    }
    &.danger {
      color: $color-danger;
    }
    &.danger:hover {
      background-color: rgba($color-danger, 0.04);
    }
    &.danger:active {
      background-color: rgba($color-danger, 0.1);
    }
    &-cancel {
      font-weight: bold;
    }
  }
}

@keyframes modal-fade-on {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes modal-body-on {
  0% {
    opacity: 0;
    transform: (scale(1.15) translate(-50%, -50%));
  }

  100% {
    opacity: 1;
    transform: (scale(1) translate(-50%, -50%));
  }
}
