@import 'bourbon', '../../../commons/styles/variables', '../../../commons/styles/icons';

.topbar-mailinglists {
  align-items: stretch;
  flex-direction: column;
  height: 148px;

  &-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  &-filters {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 0 0 20px;

    .filters-title {
      flex: 0 30px;
      text-align: right;
      color: $color-text-gray;

      span {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 10px;
        background-repeat: no-repeat;
        background-size: 20px 20px;
        background-position: 0 50%;
        background-image: icon(filter, rgba($color-text-gray, 0.25));
      }
    }

    .filters-content {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      flex-grow: 1;
      gap: 10px;

      @media (max-width: $device-width-desktop - 1px) {
        gap: 5px;
      }

      .input {
        flex: 1;
      }
    }
  }

  @media (max-width: $device-width-tablet - 1px) {
    height: 160px;
    flex-shrink: 0;

    &-filters {
      margin-left: -50px;

      .filters-content {
        gap: 3px;

        .input {
          flex-basis: 26%;
          input {
            margin: 0;
          }
        }
      }
    }
  }
}
